export const allData = {
  solutionData: [
    {
      id: 1,
      img: './assets/img/season-oriented-img.webp',
      heading: 'Season Oriented Packaging',
      paragraph:
        'ALFT offers a wide variety of services. One of which is the ability to provide our clientele with season-oriented packaging for any of their chosen products. Our team at ALFT will design and produce a package that matches each season. So, if you want the packaging to suit the christmas vibe you’re looking for, we’ve got just the service lined-up for you!'
    },
    {
      id: 2,
      img: './assets/img/cost-effective-img.webp',
      heading: 'Innovative Cost-effective SKU’s',
      paragraph:
        'Customers are dynamic. People’s tastes and preferences change a lot. So, it would be wise to cater to the market. At ALFT we give our partners the full length of the service. So, our team will focus on creating innovative cost-effective SKU’s so that your product can sell better in the market of your choice.'
    },
    {
      id: 3,
      img: './assets/img/colors-same-product-img.webp',
      heading: 'Range of Colours for the same product',
      paragraph:
        'The packaging says a lot about the product. As your trusty partner, we offer a wide range of colours for the same product. When your product is presented in a diverse set of colours, it will trigger what we call the ‘Colour Psychology’ of your customers. In simple terms, your product will sell better. '
    },
    {
      id: 4,
      img: './assets/img/packaging-messaging-img.webp',
      heading: 'Range of messaging on packaging',
      paragraph:
        'If you truly want to connect with your customer there is nothing quite like numbers and words. You can fully utilise the packaging for your product, and tell your customers the story behind your product. It’s time you maximised your packaging real-estate.'
    },
    {
      id: 5,
      img: './assets/img/colors-flavors-img.webp',
      heading: 'Range of Colours to match flavours',
      paragraph:
        'Our flexography can print a range of colours that you’d like printed on your product. Mix and match colours until you bring out what you believe would be the perfect colour that complements the taste.'
    },
    {
      id: 6,
      img: './assets/img/color-options.webp',
      heading: 'Colour Options',
      paragraph:
        'ALFT will help you make your products look the best they can. How? Our colour palette includes more than 3000 tones that you can use on your products. It’s time to add that pop of colour and make your products come alive!'
    }
  ],
  testimonialVideoData: [
    {
      client: 'Hajar Alafifi Laadel',
      role: 'Chairperson at Uniliver Sri Lanka',
      image: './assets/img/abt-uni.webp',
      videoID: 'eq3TBbZaPrs',
      videoTitle: 'What Our Partners Say'
    },
    {
      client: 'Steve Smith',
      role: 'Principal Consultant',
      image: './assets/img/miraclon-logo.webp',
      videoID: 'CEYYaD1Qn7g',
      videoTitle: 'What Our Partners Say'
    },
    {
      client: 'Joerg Kullwitz ',
      role: 'Flexographic Business Director - Asia Pacific',
      image: './assets/img/miraclon-logo.webp',
      videoID: 'k8c8MCMp9jI',
      videoTitle: 'What Our Partners Say'
    },
    {
      client: 'Rahul Gupta',
      role: 'Technical Application Specialist',
      image: './assets/img/miraclon-logo.webp',
      videoID: 'B8wBWHL5E38',
      videoTitle: 'What Our Partners Say'
    }
  ],
  pdData: [
    {
      id: 1,
      img: './assets/img/home-beverages-img.webp',
      type: 'beverages'
    },
    {
      id: 2,
      img: './assets/img/home-food-img.webp',
      type: 'food'
    },
    {
      id: 3,
      img: './assets/img/home-frozen-food-img.webp',
      type: 'frozen-food'
    },
    {
      id: 4,
      img: './assets/img/home-personal-care-img.webp',
      type: 'personal-care'
    },
    {
      id: 5,
      img: './assets/img/home-pet-care-img.webp',
      type: 'pet-care'
    },
    {
      id: 6,
      img: './assets/img/home-healthcare-img.webp',
      type: 'health-care'
    },
    {
      id: 7,
      img: './assets/img/home-home-care-img.webp',
      type: 'home-care'
    }
  ]
};
export const prodcutsPageData = {
  beverages: {
    img: './assets/img/home-beverages-img.webp',
    heading: 'Beverages Industry',
    para1:
      'When you think of your favourite beverage, the chances are the packaging for it is done by us! We focus on quality so that you can rest easy. We package a lot of different products like,',
    categories: [
      'Tea leaves',
      'Coffee beans ',
      'Tea bags',
      'Soft drinks & wine',
      'Powdered drinks'
    ],
    para2:
      'So, whatever your product may be, rest assured we will show your product the same care that you have shown.'
  },
  foodIndustry: {
    img: './assets/img/home-food-img.webp',
    heading: 'Food Industry ',
    para1:
      'Our purpose-built factories will get the job done no matter the industry. Our experienced staff are here to get you what you need. Right now ALFT provides packaging for,',
    categories: [
      'Snack bars',
      'Nuts & dried fruits ',
      'Sausages and meat substitute',
      'Biscuits and crackers',
      'Sugar confectionery and chocolate',
      'Crisps',
      'Baby food (wet & dry) ',
      'Milk products and ice cream ',
      'Milk formula',
      'Processed fruits & vegetables',
      'Processed meat',
      'Soups & sauces'
    ],
    para2:
      'While we continue our work in several industries, we pay attention to the needs of every one of our clients.'
  },
  personalCare: {
    img: './assets/img/home-personal-care-img.webp',
    heading: 'Personal Care ',
    para1:
      'Everything from toilet care to baby diapers, there is a reason our clients stick around. While quality is guaranteed, you can choose from a wide variety of colours, materials and methods for your packaging needs. Our personal care product line-up includes but not limited to,',
    categories: ['Shampoo and creams ', 'Sanitary napkins', 'Baby diapers'],
    para2: `<a href = "/contact"> Get in touch with us! </a> Make your product stand out.`
  },
  petCare: {
    img: './assets/img/home-pet-care-img.webp',
    heading: 'Pet Care',
    para1:
      'Our pets are important to us. This is why when you’re in the pet care industry, it needs the best packaging possible, attracting the most caring customers. We package,',
    categories: ['Pet food (wet & dry) '],
    para2:
      'We built a list of clients from different backgrounds operating in the pet care industry. They have trusted us with their products, and So can you! '
  },
  healthCare: {
    img: './assets/img/home-healthcare-img.webp',
    heading: 'Health Care',
    para1:
      'Healthcare is important to anyone. Our attention to detail may just be the quality you are looking for. Currently, we package a lot of different products like,',
    categories: ['Tablets/capsules ', 'Gloves', 'Procedure kits'],
    para2:
      'If you think our process can add value to your product, don’t hesitate to enquire about our products and services. '
  },
  homeCare: {
    img: './assets/img/home-home-care-img.webp',
    heading: 'Home Care',
    para1:
      'Our proven track record spans a lot of industries. One such industry is Home care. We package a range of products, such as,',
    categories: ['Gloves', 'Dishwashing Detergents ', 'Fabric care', ' Household wipes'],
    para2:
      'ALFT provides the best packaging possible, with the best service, handled by some of the most experienced staff. All that’s needed is your product! '
  },
  frozenFood: {
    img: './assets/img/home-frozen-food-img.webp',
    heading: 'Frozen Food',
    para1:
      'The chances are that you have come across frozen food. You might like it might not. What’s for sure is that if you are looking to package your own frozen food product, ALFT can help you out! Some of the products we package in the frozen food industry are,',
    categories: ['Sea food', 'Sausage ', 'Meat Packaging'],
    para2: ' '
  }
};

export const dblCardData = [
  {
    img: '/assets/img/fssc.webp',
    storng: 'FSSC 22000',
    text: 'is used to control food safety risks. The certification requirements provide a rigorous system to manage food safety risks and provide safe products for use. '
  },
  {
    img: '/assets/img/iso.webp',
    storng: 'ISO 22000:2005',
    text: ' specifies requirements for a food safety management system where an organization in the food chain needs to demonstrate its ability to control food safety hazards to ensure safe human consumption.  '
  },
  {
    img: '/assets/img/haccp.webp',
    storng: 'HACCP Hazard ',
    text: 'Analysis and Critical Control Points is an internationally recognized standard for food safety management.        '
  },
  {
    img: '/assets/img/iso.webp',
    storng: '‘Good Manufacturing Practice’',
    text: ' is a quality standard for ensuring the manufacturing practices are consistently produced, controlled and implemented according to the set quality of manufacturing products.         '
  },
  {
    img: '/assets/img/iso.webp',
    storng: 'ISO 9001:2015',
    text: ' specifies requirements for a quality management system when an organisation needs to demonstrate its ability to consistently provide products & services that meet applicable statutory and regulatory requirements, and aims to increase customer satisfaction through the successful application of the system. '
  }
];

export const blogData = [
  {
    id: 1,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 2,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'events',
    date: 'Feb 15, 2022'
  },
  {
    id: 3,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'csr project',
    date: 'Feb 15, 2022'
  },
  {
    id: 4,
    img: './assets/img/blog-img.webp',
    title: 'Lor444444em ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 5,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 6,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 7,
    img: './assets/img/blog-img.webp',
    title: 'Lorem5555 ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 8,
    img: './assets/img/blog-img.webp',
    title: 'Lorem 6666 ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 9,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 10,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 11,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 12,
    img: './assets/img/blog-img.webp',
    title: 'Lorem7777 ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 13,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 14,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 15,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 16,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 17,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 18,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 19,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    id: 20,
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },

  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'news',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem 444545ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'csr project',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'csr project',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Loremerere ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'csr project',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem56565 ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'csr project',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem 56767ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'csr project',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem8990 ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'csr project',
    date: 'Feb 15, 2022'
  },
  {
    img: './assets/img/blog-img.webp',
    title: 'Lorem -===ipsum dolor sit amet, consectetur adipiscing elit. Sollicitudin velit.',
    category: 'csr project',
    date: 'Feb 15, 2022'
  }
];

export const categoryData = {
  solid: [
    {
      id: '1',
      img: './assets/img/dry.webp',
      title: 'Dry Food',
      menuCardData: [
        {
          id: '1',
          category: 'Dry Food',
          title: 'Center Seal Pouch',
          info: 'A center seal pouch is a flexible packaging used for storing and transporting products. It is created by sealing a flat sheet of material in the center to make a pouch, leaving one end open for the product to be inserted and sealed with a heat sealer or adhesive. It is customizable and can be printed with branding and product information.',
          img: './assets/img/center-seal-pouch.webp'
        }
      ]
    },
    {
      id: '2',
      img: './assets/img/frozen.webp',
      title: 'Frozen Food',
      menuCardData: [
        {
          id: '1',
          category: 'Frozen Food',
          title: '3 Side Seal Pouch',
          info: 'A three-side seal pouch is a packaging type that is sealed on three sides, leaving one side open for the product to be inserted and sealed. It is customizable, lightweight, and made of flexible materials that can protect products against moisture and other environmental factors. Three-side seal pouches offer a cost-effective way to package products.',
          img: './assets/img/3-side-seal-pouch.webp'
        }
      ]
    },
    {
      id: '3',
      img: './assets/img/bev.webp',
      title: 'Beverage',
      menuCardData: [
        {
          id: '3',
          category: 'Beverage',
          title: 'Standup Pouch',
          info: 'A stand-up pouch is a flexible packaging that stands upright on its own with a flat bottom and two or three expanding sides. It is made of foldable material and sealed with a zipper or heat seal at the top. It is lightweight, customizable, and can be printed with branding and product information.',
          img: './assets/img/stand-up-pouch.webp'
        }
      ]
    },
    {
      id: '4',
      img: './assets/img/home.webp',
      title: 'Home Care',
      menuCardData: [
        {
          id: '4',
          category: 'Home Care',
          title: 'Center Seal Pouch',
          info: 'A center seal pouch is a flexible packaging used for storing and transporting products. It is created by sealing a flat sheet of material in the center to make a pouch, leaving one end open for the product to be inserted and sealed with a heat sealer or adhesive. It is customizable and can be printed with branding and product information.',
          img: './assets/img/center-seal-pouch.webp'
        },
        {
          id: '5',
          category: 'Home Care',
          title: 'Standup Pouch',
          info: 'A stand-up pouch is a flexible packaging that stands upright on its own with a flat bottom and two or three expanding sides. It is made of foldable material and sealed with a zipper or heat seal at the top. It is lightweight, customizable, and can be printed with branding and product information.',
          img: './assets/img/stand-up-pouch.webp'
        }
      ]
    },
    {
      id: '5',
      img: './assets/img/pet.webp',
      title: 'Pet Care',
      menuCardData: [
        {
          id: '6',
          category: 'Pet Care',
          title: '3 Side Seal Pouch',
          info: 'A three-side seal pouch is a packaging type that is sealed on three sides, leaving one side open for the product to be inserted and sealed. It is customizable, lightweight, and made of flexible materials that can protect products against moisture and other environmental factors. Three-side seal pouches offer a cost-effective way to package products.',
          img: './assets/img/3-side-seal-pouch.webp'
        }
      ]
    },
    {
      id: '6',
      img: './assets/img/personal.webp',
      title: 'Personal Care',
      menuCardData: [
        {
          id: '7',
          category: 'Personal Care',
          title: 'Center Seal Pouch',
          info: 'A center seal pouch is a flexible packaging used for storing and transporting products. It is created by sealing a flat sheet of material in the center to make a pouch, leaving one end open for the product to be inserted and sealed with a heat sealer or adhesive. It is customizable and can be printed with branding and product information.',
          img: './assets/img/center-seal-pouch.webp'
        },
        {
          id: '8',
          category: 'Personal Care',
          title: 'Standup Pouch',
          info: 'A stand-up pouch is a flexible packaging that stands upright on its own with a flat bottom and two or three expanding sides. It is made of foldable material and sealed with a zipper or heat seal at the top. It is lightweight, customizable, and can be printed with branding and product information.',
          img: './assets/img/stand-up-pouch.webp'
        }
      ]
    },
    {
      id: '7',
      img: './assets/img/health.webp',
      title: 'Health Care',
      menuCardData: [
        {
          id: '9',
          category: 'Health Care',
          title: '3 Side Seal Pouch',
          info: 'A three-side seal pouch is a packaging type that is sealed on three sides, leaving one side open for the product to be inserted and sealed. It is customizable, lightweight, and made of flexible materials that can protect products against moisture and other environmental factors. Three-side seal pouches offer a cost-effective way to package products.',
          img: './assets/img/3-side-seal-pouch.webp'
        }
      ]
    }
  ],
  liquid: [
    {
      id: '1',
      img: './assets/img/wet.webp',
      title: 'Wet Food',
      menuCardData: [
        {
          id: '10',
          category: 'Wet Food',
          title: 'Standup Pouch',
          info: 'A stand-up pouch is a flexible packaging that stands upright on its own with a flat bottom and two or three expanding sides. It is made of foldable material and sealed with a zipper or heat seal at the top. It is lightweight, customizable, and can be printed with branding and product information.',
          img: './assets/img/stand-up-pouch.webp'
        }
      ]
    },

    {
      id: '2',
      img: './assets/img/bev.webp',
      title: 'Beverage',
      menuCardData: [
        {
          id: '11',
          category: 'Beverage',
          title: 'Standup Pouch',
          info: 'A stand-up pouch is a flexible packaging that stands upright on its own with a flat bottom and two or three expanding sides. It is made of foldable material and sealed with a zipper or heat seal at the top. It is lightweight, customizable, and can be printed with branding and product information.',
          img: './assets/img/stand-up-pouch.webp'
        }
      ]
    },
    {
      id: '3',
      img: './assets/img/home.webp',
      title: 'Home Care',
      menuCardData: [
        {
          id: '12',
          category: 'Home Care',
          title: 'Standup Pouch',
          info: 'A stand-up pouch is a flexible packaging that stands upright on its own with a flat bottom and two or three expanding sides. It is made of foldable material and sealed with a zipper or heat seal at the top. It is lightweight, customizable, and can be printed with branding and product information.',
          img: './assets/img/stand-up-pouch.webp'
        }
      ]
    },

    {
      id: '4',
      img: './assets/img/personal.webp',
      title: 'Personal Care',
      menuCardData: [
        {
          id: '14',
          category: 'Personal Care',
          title: 'Standup Pouch',
          info: 'A stand-up pouch is a flexible packaging that stands upright on its own with a flat bottom and two or three expanding sides. It is made of foldable material and sealed with a zipper or heat seal at the top. It is lightweight, customizable, and can be printed with branding and product information.',
          img: './assets/img/stand-up-pouch.webp'
        }
      ]
    },
    {
      id: '5',
      img: './assets/img/health.webp',
      title: 'Health Care',
      menuCardData: [
        {
          id: '13',
          category: 'Health Care',
          title: 'Standup Pouch',
          info: 'A stand-up pouch is a flexible packaging that stands upright on its own with a flat bottom and two or three expanding sides. It is made of foldable material and sealed with a zipper or heat seal at the top. It is lightweight, customizable, and can be printed with branding and product information.',
          img: './assets/img/stand-up-pouch.webp'
        }
      ]
    }
  ]
};

export const photoSliderData = [
  {
    img: './assets/img/cbl.webp',
    size: 'w-50'
  },
  {
    img: './assets/img/logo.webp',
    size: 'w-50'
  },
  {
    img: './assets/img/maliban.webp',
    size: 'w-100'
  },
  {
    img: './assets/img/nestle.webp',
    size: 'w-100'
  },
  {
    img: './assets/img/pelawatta.webp',
    size: 'w-75'
  },
  {
    img: './assets/img/cargills.webp',
    size: 'w-75'
  },
  { img: './assets/img/keells.webp', size: 'w-75' },
  { img: './assets/img/link-natural.webp', size: 'w-100' },
  { img: './assets/img/prima.webp', size: 'w-100' }
];
