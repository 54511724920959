import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import contactService from '../services/contactService';

const initialState = {
  response: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ''
};

export const postResponse = createAsyncThunk('post-response', async (data, thunkAPI) => {
  try {
    return await contactService.postResponse(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(postResponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.response = action.payload;
      })
      .addCase(postResponse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = contactSlice.actions;
export default contactSlice.reducer;
