import React, { useEffect, useRef, useState } from 'react';

const VideoPlayer = ({ videoId, title }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="video-player-container">
      {!isActive ? (
        <>
          <div onClick={() => setIsActive(true)} className="play-button-container" id="cover">
            <img src="/assets/img/play-button.webp" alt="play button" />
          </div>
          <img
            className="thumbnail"
            loading="lazy"
            src={`https://img.youtube.com/vi_webp/${videoId}/hqdefault.webp`}
            alt=""
          />
        </>
      ) : (
        <iframe
          width="100%"
          height="100%"
          title={title}
          src={`https://www.youtube-nocookie.com/embed/${videoId}?controls=0&rel=0&autoplay=1`}
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          id="player"
          className="video-player-iframe"
          data-video-id={videoId}
        />
      )}
    </div>
  );
};

export default VideoPlayer;
