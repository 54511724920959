import React from 'react';
import PhotoSlider from '../../components/photoSlider';

const OurCustomers = () => {
  return (
    <section className="customers">
      <h1 className="main-h1">A Glance at our Customers</h1>
      <PhotoSlider></PhotoSlider>
    </section>
  );
};

export default OurCustomers;
