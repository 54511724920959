import React from 'react';
import { Container } from 'react-bootstrap';
import LetsTalkBanner from '../../components/LetsTalkBanner';
import { Vacancies } from '../../components/Vacancies';
import MainLayout from '../../layouts/MainLayout';

const Careers = () => {
  return (
    <MainLayout title="Careers | ALFT Packaging (Pvt) Ltd">
      <section id="careers-page">
        <Container>
          <section className="careers">
            <h1 className="main-heading">Come join with us</h1>
            <p className="mb-lg-5 mt-lg-5 mt-sm-4">
              ALFT offers you the chance to join the big leagues! In an unmatched work environment,
              our employees thrive as the core of our company. There are many advantages when you
              join with Sri Lanka’s best,
            </p>

            <div className="logos-wrapper">
              <div className="logo-box">
                <img src="./assets/img/cIcon2.webp" alt="" />
                <p>Our priority is employee safety</p>
              </div>
              <div className="logo-box">
                <img src="./assets/img/cIcon3.webp" alt="" />
                <p>An opportunity to work at an Industry-leading enterprise</p>
              </div>
              <div className="logo-box">
                <img src="./assets/img/cIcon4.webp" alt="" />
                <p>
                  You will have the chance to learn, grow and develop your career further with us
                </p>
              </div>
              <div className="logo-box">
                <img src="./assets/img/cIcon6.webp" alt="" />
                <p>
                  We provide all our employees with more than adequate pay at above-market pay rates
                </p>
              </div>
              <div className="logo-box">
                <img src="./assets/img/cIcon5.webp" alt="" />
                <p>
                  ALFT provides free in-house accommodation for any employee that so requires it
                </p>
              </div>
            </div>
            <div className="career-banner">
              <LetsTalkBanner
                heading={'Geared with a team with expertise of over 20 years'}
                route={'/contact'}
              ></LetsTalkBanner>
            </div>

            <section className="jobs">
              <h1 className="main-h1">Available Vacancies</h1>
              <div className="my-5">
                <Vacancies></Vacancies>
              </div>
            </section>
          </section>
        </Container>
      </section>
    </MainLayout>
  );
};

export default Careers;
