import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Paginator from 'react-hooks-paginator';
import BlogCard from '../../components/BlogCard';
import CustomDropdown from '../../components/CustomDropdown';
import MainLayout from '../../layouts/MainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPosts } from '../../redux/reducers/newsAndEventSlice';
import Spinner from '../../components/Spinner';

const NewsAndEvents = () => {
  const [selected, setSelected] = useState('Filter by Category');

  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [data, setData] = useState([]);
  const pageLimit = 6;
  const dispatch = useDispatch();
  const { newsAndEvents, isLoading } = useSelector((state) => state.newsAndEvents);

  useEffect(() => {
    dispatch(getAllPosts());
  }, [dispatch]);

  useEffect(() => {
    if (newsAndEvents.length > 0) {
      setData(newsAndEvents);
      setCurrentData(newsAndEvents?.slice(offset, offset + pageLimit));
    }
  }, [newsAndEvents, offset]);

  const handleSelect = (option) => {
    setSelected(option);
    if (option === 'All Categories') {
      setCurrentData(newsAndEvents.slice(offset, offset + pageLimit));
      setData(newsAndEvents);
      setCurrentPage(1);
    } else {
      const filterdData = newsAndEvents?.filter(
        (i) => i.category.toLowerCase() === option.toLowerCase()
      );
      setCurrentData(filterdData.slice(offset, offset + pageLimit));
      setData(filterdData);
      setCurrentPage(1);
    }
  };

  return (
    <MainLayout title="News And Events | ALFT Packaging (Pvt) Ltd">
      <section className="news-events">
        <h1 className="main-heading">News from the Factory</h1>
        <div className="dropdown-w-wrap ml-auto mt-5 mt-lg-3 mb-4 mt-md-4 mb-md-1 mb-lg-2">
          <CustomDropdown selected={selected} handleSelect={handleSelect} />
        </div>
        <div className="blogs-wrapper ">
          {isLoading ? (
            <div className="spinner-container">
              <Spinner />
            </div>
          ) : (
            <Row xs={1} md={2} lg={3} className="gx-5 gy-5">
              {currentData.map((blog, idx) => (
                <Col className="margin-top-50px" key={idx}>
                  <BlogCard blog={blog}></BlogCard>
                </Col>
              ))}
            </Row>
          )}
        </div>
        {!isLoading && currentData.length === 0 && (
          <div
            style={{
              display: 'inline-flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '50px'
            }}
          >
            <span
              style={{
                maxWidth: '50%',
                textAlign: 'center',
                fontWeight: '500'
              }}
            >
              No items to display. Try a different category or check back later.
            </span>
          </div>
        )}
        <div className="pro-pagination-style text-center ">
          <Paginator
            totalRecords={data.length}
            pageLimit={pageLimit}
            pageNeighbours={0}
            setOffset={setOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageContainerClass="mb-0 mt-0"
            pagePrevClass="next-link"
            pageNextClass="next-link"
          />
        </div>
      </section>
    </MainLayout>
  );
};

export default NewsAndEvents;
