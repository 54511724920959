import React, { useState, useEffect } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import FooterBottom from './FooterBottom';
import qualityPolicy from '../../assets/policy/QualityPolicy.pdf';
import EnvironmentalPolicy from '../../assets/policy/EnvironmentalPolicy.pdf';
import FoodSafetyPolicy from '../../assets/policy/FoodSafetyPolicy.pdf';
import OccupationalHealthandSafetyPolicy from '../../assets/policy/OccupationalHealthandSafetyPolicy.pdf';
import { useDispatch, useSelector } from 'react-redux';
import { newSubscription } from '../../redux/reducers/subscriptionSlice';
import Spinner from '../../components/Spinner';
import { errorToast, successToast } from '../../components/Toasts';
import { isEmail } from '../../utils/methods';

const Footer = () => {
  const [rotate, setRotate] = useState({
    company: false,
    services: false,
    products: false
  });
  const [email, setEmail] = useState('');
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const dispatch = useDispatch();
  const { isLoading, isError, message } = useSelector((state) => state.subscription);

  const onSubmit = () => {
    const data = {
      email
    };
    if (isEmail(email)) {
      setSubscriptionLoading(true);

      dispatch(newSubscription(data));
      setTimeout(() => {
        if (isLoading) {
          setSubscriptionLoading(true);
        } else {
          setSubscriptionLoading(false);
          successToast('You Have Subscribed To Our Newsletter');
        }
      }, 1000);
    } else {
      errorToast('Please enter a valid email address');
    }
  };
  useEffect(() => {
    if (isError) {
      errorToast(message);
    }
  }, [isError, message]);

  return (
    <footer>
      {/* subscribe  */}
      <div className="subscribe">
        <h3>Get the latest updates from us!</h3>
        <div>
          <input
            type="email"
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <button onClick={onSubmit} className="subscribe-btn">
            {subscriptionLoading ? (
              <>
                Subscribing <Spinner />
              </>
            ) : (
              'Subscribe'
            )}
          </button>
        </div>
      </div>
      <hr className="d-none d-lg-block" />

      {/* main footer navigations  */}
      <div className="main-footer">
        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-7 col-md-12 col-lg-6 seven-three">
              <div className="row">
                <div className="col-sm-4 col-md-12 col-lg-3">
                  {/* dropdown for mobile  */}
                  <div className="d-block d-lg-none links-wrap">
                    <Accordion>
                      <Accordion.Toggle
                        as={Button}
                        onClick={() => setRotate({ ...rotate, company: !rotate.company })}
                        variant="link"
                        eventKey="0"
                      >
                        <h5>Company </h5>{' '}
                        <span>
                          <svg
                            className={`${rotate.company && 'change-dir'} fa fa-angle-down`}
                            aria-hidden="true"
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                        </span>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <ul>
                          <li>
                            <NavLink activeClassName="active-style" to="/">
                              Home
                            </NavLink>
                          </li>
                          <li>
                            <NavLink activeClassName="active-style" to="/about">
                              About us
                            </NavLink>
                          </li>
                          <li>
                            <NavLink activeClassName="active-style" to="/news-and-events">
                              News & Events
                            </NavLink>
                          </li>
                          <li>
                            <NavLink activeClassName="active-style" to="/careers">
                              Careers
                            </NavLink>
                          </li>
                          <li>
                            <NavLink activeClassName="active-style" to="/contact">
                              Contact us
                            </NavLink>
                          </li>
                        </ul>
                      </Accordion.Collapse>
                    </Accordion>
                  </div>

                  <div className="links-wrap d-none d-lg-block">
                    <h5>Company</h5>
                    <ul>
                      <li>
                        <NavLink activeClassName="active-style" to="/">
                          Home
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClassName="active-style" to="/about">
                          About us
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClassName="active-style" to="/news-and-events">
                          News & Events
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClassName="active-style" to="/careers">
                          Careers
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClassName="active-style" to="/contact">
                          Contact us
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4 col-lg-6 col-md-12">
                  {/* dropdown for mobile  */}
                  <div className="d-block d-lg-none links-wrap">
                    <Accordion>
                      <Accordion.Toggle
                        as={Button}
                        onClick={() => setRotate({ ...rotate, services: !rotate.services })}
                        variant="link"
                        eventKey="1"
                      >
                        <h5>Our Innovative Solutions </h5>{' '}
                        <span>
                          <svg
                            className={`${rotate.serices && 'change-dir'} fa fa-angle-down`}
                            aria-hidden="true"
                            stroke="currentColor"
                            fill="none"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                        </span>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="1">
                        <ul>
                          <li>
                            <NavHashLink
                              activeClassName="active-style"
                              to={'/#season-oriented-packaging'}
                              smooth
                            >
                              Season Oriented Packaging
                            </NavHashLink>
                          </li>

                          <li>
                            <NavHashLink
                              activeClassName="active-style"
                              to={'/#innovative-cost-effective-sku’s'}
                              smooth
                            >
                              Innovative Cost-effective SKU’s
                            </NavHashLink>
                          </li>

                          <li>
                            <NavHashLink
                              activeClassName="active-style"
                              to={'/#range-of-colours-for-the-same-product'}
                              smooth
                            >
                              Range of Colours for the same product
                            </NavHashLink>
                          </li>

                          <li>
                            <NavHashLink
                              activeClassName="active-style"
                              to={'/#range-of-messaging-on-packaging'}
                              smooth
                            >
                              Range of messaging on packaging
                            </NavHashLink>
                          </li>

                          <li>
                            <NavHashLink
                              activeClassName="active-style"
                              to={'/#range-of-colours-to-match-flavours'}
                              smooth
                            >
                              Range of Colours to match flavours
                            </NavHashLink>
                          </li>

                          <li>
                            <NavHashLink
                              activeClassName="active-style"
                              to={'/#colour-options'}
                              smooth
                            >
                              Colour Options
                            </NavHashLink>
                          </li>
                        </ul>
                      </Accordion.Collapse>
                    </Accordion>
                  </div>

                  <div className="links-wrap  d-none d-lg-block">
                    <h5>Our Innovative Solutions</h5>
                    <ul>
                      <li>
                        <NavHashLink
                          activeClassName="active-style"
                          to={'/#season-oriented-packaging'}
                          smooth
                        >
                          Season Oriented Packaging
                        </NavHashLink>
                      </li>

                      <li>
                        <NavHashLink
                          activeClassName="active-style"
                          to={'/#innovative-cost-effective-sku’s'}
                          smooth
                        >
                          Innovative Cost-effective SKU’s
                        </NavHashLink>
                      </li>

                      <li>
                        <NavHashLink
                          activeClassName="active-style"
                          to={'/#range-of-colours-for-the-same-product'}
                          smooth
                        >
                          Range of Colours for the same product
                        </NavHashLink>
                      </li>

                      <li>
                        <NavHashLink
                          activeClassName="active-style"
                          to={'/#range-of-messaging-on-packaging'}
                          smooth
                        >
                          Range of messaging on packaging
                        </NavHashLink>
                      </li>

                      <li>
                        <NavHashLink
                          activeClassName="active-style"
                          to={'/#range-of-colours-to-match-flavours'}
                          smooth
                        >
                          Range of Colours to match flavours
                        </NavHashLink>
                      </li>

                      <li>
                        <NavHashLink activeClassName="active-style" to={'/#colour-options'} smooth>
                          Colour Options
                        </NavHashLink>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-sm-4 col-md-12 col-lg-3">
                  {/* dropdown for mobile  */}
                  <div className="d-block d-lg-none links-wrap">
                    <Accordion>
                      <div onClick={() => setRotate({ ...rotate, products: !rotate.products })}>
                        <Accordion.Toggle as={Button} variant="link" eventKey="2">
                          <h5>Products range </h5>{' '}
                          <span>
                            <svg
                              className={`${rotate.products && 'change-dir'} fa fa-angle-down`}
                              aria-hidden="true"
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <polyline points="6 9 12 15 18 9"></polyline>
                            </svg>
                          </span>
                        </Accordion.Toggle>
                      </div>

                      <Accordion.Collapse eventKey="2">
                        <ul>
                          <li>
                            <NavLink to="/beverages" activeClassName="active-style">
                              Beverages
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/food" activeClassName="active-style">
                              Food
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/frozen-food" activeClassName="active-style">
                              Frozen Food
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/personal-care" activeClassName="active-style">
                              Personal Care
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/pet-care" activeClassName="active-style">
                              Pet Care
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/health-care" activeClassName="active-style">
                              Health Care
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/home-care" activeClassName="active-style">
                              Home Care
                            </NavLink>
                          </li>
                        </ul>
                      </Accordion.Collapse>
                    </Accordion>
                  </div>

                  <div className="links-wrap d-none d-lg-block">
                    <h5>Products range</h5>
                    <ul>
                      <li>
                        <NavLink to="/beverages" activeClassName="active-style">
                          Beverages
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/food" activeClassName="active-style">
                          Food
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/frozen-food" activeClassName="active-style">
                          Frozen Food
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/personal-care" activeClassName="active-style">
                          Personal Care
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/pet-care" activeClassName="active-style">
                          Pet Care
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/health-care" activeClassName="active-style">
                          Health Care
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/home-care" activeClassName="active-style">
                          Home Care
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-md-12 col-lg-6 five-two">
              <div className="row">
                <div className="col-sm-6 col-md-12 col-lg-5">
                  <div className="links-wrap contact-wrap">
                    <ul>
                      <li>
                        <a
                          href="https://www.google.com/maps/place/Alft+Packaging+Co.+(Pvt)+Ltd/@7.1171804,79.8986322,17z/data=!3m1!4b1!4m5!3m4!1s0x3ae2f066c957fde1:0x276e001d23250fb0!8m2!3d7.1171804!4d79.9008209"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          <span className="d-flex align-items-start">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 384 512"
                              className="mr-3"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ width: '45px', height: '23px' }}
                            >
                              <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                            </svg>
                            ALFT Packaging Company (Pvt) Ltd No 52, Cinnamon Garden <br />
                            Ekala, Kotugoda
                          </span>
                        </a>
                      </li>
                      <li>
                        <span className="d-flex align-items-center">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            className="mr-3"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ width: '17px', height: '23px', color: 'rgb(149, 244, 0)' }}
                          >
                            <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                          </svg>

                          <div>
                            <a href="tel:+94 706 505 192">Tel: +94 706 505 192</a> <br />
                            <a href="fax:+94 112 248 767"> Fax: +94 112 248 767</a>
                          </div>
                        </span>
                      </li>
                      <li>
                        <a href="malito:info@alft.lk">
                          <span className="d-flex align-items-center">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 512 512"
                              className="mr-3"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ width: '17px', height: '23px' }}
                            >
                              <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
                            </svg>
                            info@alft.lk
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-6 col-md-12 col-lg-7">
                  <div className="logo-wrapper">
                    <img src="/assets/img/alft-logo.webp" className="img-fluid" alt="Alft Logo" />
                    <div className="socials-wrap">
                      <a
                        href="https://www.facebook.com/alftpackaging"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M21.1 0.299805H2.89999C1.46999 0.299805 0.299988 1.4698 0.299988 2.8998V21.0998C0.299988 22.5311 1.46999 23.6998 2.89999 23.6998H12V14.5998H9.39999V11.3823H12V8.7173C12 5.9041 13.5756 3.9281 16.8958 3.9281L19.2397 3.9307V7.3172H17.6836C16.3914 7.3172 15.9 8.287 15.9 9.1866V11.3836H19.2384L18.5 14.5998H15.9V23.6998H21.1C22.53 23.6998 23.7 22.5311 23.7 21.0998V2.8998C23.7 1.4698 22.53 0.299805 21.1 0.299805Z"
                            fill="#0083B2"
                          />
                        </svg>
                      </a>

                      <a
                        href="https://www.linkedin.com/company/alft-packaging-company/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {' '}
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.0833438 2.07467C0.0833438 1.54658 0.293127 1.04011 0.666543 0.666698C1.03996 0.293282 1.54642 0.0834987 2.07451 0.0834987H21.9233C22.1851 0.0830713 22.4443 0.134267 22.6862 0.234154C22.9281 0.334042 23.1479 0.480659 23.333 0.665613C23.5182 0.850567 23.665 1.07022 23.7652 1.31201C23.8653 1.55379 23.9168 1.81296 23.9167 2.07467V21.9235C23.917 22.1853 23.8656 22.4445 23.7656 22.6864C23.6656 22.9283 23.5189 23.1481 23.3339 23.3333C23.1488 23.5184 22.9291 23.6652 22.6872 23.7654C22.4454 23.8655 22.1862 23.917 21.9244 23.9168H2.07451C1.81294 23.9168 1.55392 23.8653 1.31227 23.7652C1.07062 23.665 0.851071 23.5183 0.66616 23.3333C0.481249 23.1482 0.334603 22.9286 0.234601 22.6869C0.1346 22.4452 0.0832015 22.1862 0.0833438 21.9246V2.07467ZM9.51701 9.1705H12.7443V10.7912C13.2101 9.8595 14.4018 9.021 16.1925 9.021C19.6256 9.021 20.4392 10.8768 20.4392 14.2817V20.5888H16.9649V15.0573C16.9649 13.1182 16.4991 12.024 15.3161 12.024C13.6748 12.024 12.9923 13.2038 12.9923 15.0573V20.5888H9.51701V9.1705ZM3.55868 20.4404H7.03401V9.021H3.55868V20.4393V20.4404ZM7.53126 5.2965C7.53781 5.59406 7.48486 5.88994 7.37552 6.16676C7.26618 6.44358 7.10264 6.69577 6.89451 6.90853C6.68638 7.1213 6.43785 7.29035 6.1635 7.40576C5.88916 7.52117 5.59452 7.58062 5.29689 7.58062C4.99925 7.58062 4.70462 7.52117 4.43027 7.40576C4.15592 7.29035 3.90739 7.1213 3.69926 6.90853C3.49113 6.69577 3.32759 6.44358 3.21825 6.16676C3.10891 5.88994 3.05596 5.59406 3.06251 5.2965C3.07537 4.71242 3.31643 4.15661 3.73405 3.74808C4.15168 3.33955 4.71267 3.11078 5.29689 3.11078C5.8811 3.11078 6.44209 3.33955 6.85972 3.74808C7.27734 4.15661 7.5184 4.71242 7.53126 5.2965Z"
                            fill="#0083B2"
                          />
                        </svg>
                      </a>

                      {/* <a href="#" target="_blank" rel="noreferrer">
                        {" "}
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.4969 8.33156C10.2016 8.33156 8.32846 10.2047 8.32846 12.5C8.32846 14.7953 10.2016 16.6684 12.4969 16.6684C14.7922 16.6684 16.6653 14.7953 16.6653 12.5C16.6653 10.2047 14.7922 8.33156 12.4969 8.33156ZM24.9991 12.5C24.9991 10.7738 25.0147 9.0633 24.9178 7.34026C24.8209 5.33891 24.3643 3.56271 22.9008 2.09922C21.4342 0.632607 19.6611 0.179175 17.6598 0.082235C15.9336 -0.0147055 14.2231 0.000930141 12.5 0.000930141C10.7739 0.000930141 9.06333 -0.0147055 7.34029 0.082235C5.33894 0.179175 3.56274 0.635734 2.09925 2.09922C0.632637 3.56584 0.179206 5.33891 0.0822655 7.34026C-0.0146749 9.06643 0.000960659 10.777 0.000960659 12.5C0.000960659 14.223 -0.0146749 15.9367 0.0822655 17.6597C0.179206 19.6611 0.635764 21.4373 2.09925 22.9008C3.56587 24.3674 5.33894 24.8208 7.34029 24.9178C9.06646 25.0147 10.777 24.9991 12.5 24.9991C14.2262 24.9991 15.9367 25.0147 17.6598 24.9178C19.6611 24.8208 21.4373 24.3643 22.9008 22.9008C24.3674 21.4342 24.8209 19.6611 24.9178 17.6597C25.0179 15.9367 24.9991 14.2262 24.9991 12.5ZM12.4969 18.9137C8.94763 18.9137 6.08319 16.0493 6.08319 12.5C6.08319 8.95073 8.94763 6.08629 12.4969 6.08629C16.0462 6.08629 18.9106 8.95073 18.9106 12.5C18.9106 16.0493 16.0462 18.9137 12.4969 18.9137ZM19.1733 7.3215C18.3446 7.3215 17.6754 6.6523 17.6754 5.82361C17.6754 4.99493 18.3446 4.32573 19.1733 4.32573C20.002 4.32573 20.6712 4.99493 20.6712 5.82361C20.6714 6.02039 20.6328 6.21528 20.5577 6.39712C20.4825 6.57897 20.3721 6.74419 20.233 6.88333C20.0939 7.02247 19.9286 7.1328 19.7468 7.20798C19.565 7.28317 19.3701 7.32175 19.1733 7.3215Z"
                            fill="#0083B2"
                          />
                        </svg>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />

      {/* policy pages  */}
      <div className="policies">
        <NavLink activeClassName="active-style" to="/privacy-policy">
          Privacy Policy
        </NavLink>
        <a href={qualityPolicy} rel="noreferrer" target="_blank">
          Quality Policy
        </a>
        <a href={FoodSafetyPolicy} rel="noreferrer" target="_blank">
          Food Safety Policy
        </a>
        <a href={EnvironmentalPolicy} rel="noreferrer" target="_blank">
          Environmental Policy
        </a>
        <a href={OccupationalHealthandSafetyPolicy} rel="noreferrer" target="_blank">
          Occupational Health and Safety Policy
        </a>
      </div>

      {/* copyright  bottom*/}
      <FooterBottom></FooterBottom>
    </footer>
  );
};

export default Footer;
