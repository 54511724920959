import React, { useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { postApplication } from '../redux/reducers/applicantSlice';
import Spinner from './Spinner';
import { errorToast, successToast } from './Toasts';
function ApplyModal(props) {
  const [cv, setCV] = React.useState(null);
  const [applicationLoading, setApplicationLoading] = React.useState(false);
  const { register, handleSubmit, formState, reset } = useForm();

  const dispatch = useDispatch();
  const { isLoading, isError, message } = useSelector((state) => state.applicants);

  const onSubmit = (data) => {
    setApplicationLoading(true);
    const applicantData = new FormData();
    applicantData.append('name', data.name);
    applicantData.append('email', data.email);
    applicantData.append('phoneNumber', data.phone);
    applicantData.append('department', data.department);
    applicantData.append('cv', data.cv);
    applicantData.append('jobId', props.jobId);
    dispatch(postApplication(applicantData));
    setTimeout(() => {
      if (isLoading) {
        setApplicationLoading(true);
      } else {
        setApplicationLoading(false);
        reset();
        props.onHide();
        successToast('Your Job Application Has Been Sent');
      }
    }, 1000);
  };

  useEffect(() => {
    if (isError) {
      errorToast(message);
    }
  }, [isError, message]);

  const handleCVChange = (changeEvent) => {
    setCV(changeEvent.target.files[0]);
  };

  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
      <div className="apply">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h3>Send us your application</h3>
          <div className="register">
            <div className="mt-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <label for="name" id="name">
                    Full name
                  </label>
                  <input
                    type="text"
                    className={`${formState.errors.name ? 'err-input' : 'cor-input'}`}
                    {...register('name', { required: true })}
                  />
                </div>
                <div>
                  <label for="Email" id="Email">
                    Email
                  </label>
                  <input
                    className={`${formState.errors.email ? 'err-input' : 'cor-input'}`}
                    type="email"
                    {...register('email', { required: true })}
                  />
                </div>
                <div>
                  <label for="phone" id="phone">
                    Phone Number
                  </label>
                  <input
                    className={`${formState.errors.phone ? 'err-input' : 'cor-input'}`}
                    type="number"
                    {...register('phone', {
                      required: true,
                      pattern: {
                        value: /^[0-9]*$/
                      }
                    })}
                  />
                </div>
                <div>
                  <label for="Department" id="Department">
                    Department
                  </label>
                  <input
                    type="text"
                    className={`${formState.errors.department ? 'err-input' : 'cor-input'}`}
                    {...register('department', { required: true })}
                  />
                </div>
                <div>
                  <h5> CV </h5>
                  {cv === null && (
                    <>
                      <span className="d-flex">
                        <label for="cv" className="fake-file">
                          Upload
                        </label>
                        <small style={{ marginTop: '2px', marginLeft: '3px' }}>
                          (max file size 5MB)
                        </small>
                      </span>
                      <input
                        type="file"
                        name="cv"
                        id="cv"
                        className="d-none"
                        required
                        onChange={handleCVChange}
                      />
                    </>
                  )}
                  {cv !== null && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                        marginTop: '5px'
                      }}
                    >
                      <span>{cv.name}</span>
                      <svg
                        onClick={() => setCV(null)}
                        className="remove-file"
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
                      </svg>
                    </div>
                  )}
                </div>
                <button type="submit">
                  {applicationLoading ? (
                    <>
                      Applying <Spinner />
                    </>
                  ) : (
                    'Apply'
                  )}
                </button>
              </form>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </div>
    </Modal>
  );
}

export default ApplyModal;
