import React from 'react';
import { Container } from 'react-bootstrap';

const WhyUs = () => {
  return (
    <Container>
      <section className="why-us">
        <h1 className="main-h1">Why Choose Us?</h1>
        <div className="icons-group1 row  justify-content-evenly align-items-center">
          <div className="col-lg-3 col-12 col-md-4">
            <div className="single-item">
              <img src="./assets/img/why1.webp" alt="" />
              <p className="pt-2">
                <strong>Highest</strong> technical achievement in the flexible packaging arena
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-12 col-md-4">
            <div className="single-item">
              {' '}
              <img src="./assets/img/why2.webp" alt="" />
              <p className="pt-2">
                Geared with a team with expertise of over <strong>20 years</strong>
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-12 col-md-4">
            <div className="single-item">
              {' '}
              <img src="./assets/img/why3.webp" alt="" />
              <p className="pt-2">
                Priority given to <strong>sustainability</strong> throughout the entire process
              </p>
            </div>
          </div>
          <div className="col-lg-3 col-12 col-md-4">
            <div className="single-item">
              <img src="./assets/img/why4.webp" alt="" />
              <p className="pt-2">
                <strong>Quality</strong> is not compromised at any cost
              </p>
            </div>
          </div>
        </div>

        <div className="icons-group2">
          <div>
            <img src="./assets/img/brc.webp" alt="" />
          </div>
          <div>
            <img src="./assets/img/sgs.webp" alt="" />
          </div>
          <div>
            <img src="./assets/img/sgs2.webp" alt="" />
          </div>
          <div>
            <img src="./assets/img/sgs3.webp" alt="" />
          </div>
          <div>
            <img src="./assets/img/sgs4.webp" alt="" />
          </div>
          <div>
            <img src="./assets/img/sgs5.webp" alt="" />
          </div>
        </div>
      </section>
    </Container>
  );
};

export default WhyUs;
