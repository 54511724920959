import React from 'react';
import MainLayout from '../../layouts/MainLayout';
import { Row, Col, Container } from 'react-bootstrap';
import WhyUs from '../../components/whyUs';
import LetsTalkBanner from '../../components/LetsTalkBanner';
import PhotoSlider from '../../components/photoSlider';
import AlftBanner from '../../components/AlftBanner';
import { dblCardData } from '../home/allData';

const About = () => {
  return (
    <MainLayout title="About | ALFT Packaging (Pvt) Ltd">
      <section className="bg-myLight ">
        <Container>
          <div className="vision">
            <h1>
              The only purpose-built plant in Sri <br /> Lanka for food and pharmaceuticals
            </h1>
            <p className="first-para">
              ALFT Packaging Company (Pvt) Ltd. was established in the year 2017. It supplied a
              much-demanded service of international standard, high-quality, next-generation
              packaging solutions. The BRC certification offered by the British Retail Consortium
              denotes food quality, safety and responsibility. ALFT is the only flexible packaging
              plant in Sri Lanka that has been awarded the BRC AA certification. Our team has
              managed to ensure the standards of quality since 2018 when it was first awarded.{' '}
            </p>
            <Row className="align-items-center px-3">
              <Col xs={12} md={12} lg={8}>
                <div className="img-box">
                  <img
                    className=""
                    src="./assets/img/range-of-colours-to-match-flavours.webp"
                    alt=""
                  />
                </div>
              </Col>
              <Col xs={12} md={12} lg={4}>
                <div className="d-flex flex-column flex-md-row flex-lg-column  ">
                  <div className="mission pb-1 pb-lg-4 pb-md-4  mt-2 mt-lg-0 mt-md-0">
                    <h5>OUR VISION</h5>
                    <p>Be the bench marker for Innovative Flexible packaging</p>
                  </div>
                  <div className="mission">
                    <h5>OUR MISSION</h5>
                    <ul>
                      <li> To meet the customer expectation at their highest. Comply </li>
                      <li> with quality and Food Safety management System. Upgrade </li>
                      <li> and uplift the living standards of the employees. </li>
                      <li> Contribute to social welfare and comply with social </li>
                      <li> compliances. </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
        <Container>
          <div className="double-cards">
            <p>
              ALFT is listed on the BRC’s global directory. (Link to the directory: BRCGS
              Directory). We didn’t stop there, we achieved the following certifications as well,{' '}
            </p>
            <div className="all-cards">
              {dblCardData.map((card, id) => (
                <div className="card-wrapper">
                  <div key={id} className="main-card h-100">
                    <p>
                      <strong> {card.storng}</strong> {card.text}
                    </p>
                    <img src={card.img} alt="" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
        <Container>
          <div className="logos-texts">
            <p>
              Moreover, we have earned continued trust in our work from our clients. This is why our
              clientele includes multinationals and conglomerates such as,
            </p>
            <div className="logos">
              <img src="./assets/img/abt-cbl.webp" alt="" />
              <img src="./assets/img/abt-uni.webp" alt="" />
              <img src="./assets/img/abt-nestle.webp" alt="" />
              <img src="./assets/img/abt-prima.webp" alt="" />
            </div>
            <p>
              We customise our facilities according to the needs of our customers. ALFT provides
              solutions across a wide range of industries including confectionery and food,
              beverage, personal care, health care, home care, cosmetics, and pharmaceuticals. ALFT
              services its clientele with an all-inclusive product encompassing simple
              straightforward films to highly individualized and complex high-barrier material. ALFT
              guarantees unmatched service, quality, and flexibility. This is ALFT, Serving you.
            </p>
          </div>
        </Container>
        <div className="how-different">
          <h1 className="main-h1">How are we different?</h1>
          <p>
            ALFT provides its customers with an amazing experience and a variety of options to
            choose from. This includes flexography and gravure printing facilities and many other
            value-added services. There are several advantages to choosing ALFT as your partner,
          </p>
          <Row className="gx-4 justify-content-between align-items-center">
            <Col xs={12} md={12} lg={4}>
              <div className="list-wrapper">
                <p>
                  <img className="po" src="./assets/img/polygon.svg" alt="" />

                  <span>
                    {' '}
                    Our services are crafted to perfection using nothing but absolute precision. The
                    people, equipment and factories help us improve and maintain our standards.
                  </span>
                </p>
                <p>
                  <img className="po" src="./assets/img/polygon.svg" alt="" />
                  <span>
                    {' '}
                    ALFT state-of-the-art factories are built according to Japanese standards and
                    equipped with European machinery.
                  </span>{' '}
                </p>
                <p>
                  <img className="po" src="./assets/img/polygon.svg" alt="" />

                  <span>
                    Our strength is our employees, having acquired more than 20 years of experience
                    in both the local and global markets. This boosts our precision, efficiency and
                    knowledge of the industry better than any of our rivals.{' '}
                  </span>
                </p>
              </div>
            </Col>
            <Col xs={12} md={12} lg={8}>
              <img className="photo" src="./assets/img/how-we-are-different.webp" alt="" />
            </Col>
          </Row>
        </div>
        <Container>
          <div className="difference">
            <div className="regular-header">
              <span>Flexography</span> <span>Gravure</span>
            </div>
            <div className="table-wrapper">
              <div className="single-table">
                <div className="single-header d-block d-lg-none d-md-none">Flexography</div>
                <ul className="">
                  <li>Most suitable for shorter runs</li>
                  <li>Doesn’t compromise on quality</li>
                  <li>Capacity to print different SKUs on the same web</li>
                </ul>
              </div>
              <div className="single-table">
                <div className="single-header d-block d-lg-none d-md-none">Gravure</div>
                <ul className="ml-0 ml-lg-4">
                  <li>Best for longer runs</li>
                  <li>Doesn’t compromise on quality</li>
                  <li>Capacity to print up to 5 spot colors plus the 4 process colors</li>
                </ul>
              </div>
            </div>
          </div>
        </Container>
        <section id="about-whyus">
          <WhyUs></WhyUs>
        </section>
        <LetsTalkBanner
          heading={
            'The only purpose built plant in Sri Lanka for food and pharmaceuticals packaging'
          }
          route={'/contact'}
        ></LetsTalkBanner>
        <div className="about-slider ">
          <h1 className="main-h1">A glance at our Customers</h1>
          <PhotoSlider></PhotoSlider>
        </div>
        <div className="abt-banner">
          <AlftBanner
            data={{
              heading: 'Start your Career with ALFT today!',
              btnText: 'View Vacancies',
              route: '/careers'
            }}
          ></AlftBanner>
        </div>
      </section>
    </MainLayout>
  );
};

export default About;
