import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import { Link, NavLink, useLocation, useNavigation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import useOutsideClick from '../../utils/useDetectOutsideClick';

const Header = () => {
  const [showDrop, setShowDrop] = useState(false);

  const [navbar, setNavbar] = useState(false);
  const [mobileNav, setMobileNav] = useState(false);

  const detectScroll = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    detectScroll();
    window.addEventListener('scroll', detectScroll, { passive: true });
    window.addEventListener('touchstart', detectScroll, { passive: true });
  });

  // scroll to top
  useEffect(() => {
    const body = document.querySelector('#root');

    body.scrollIntoView(
      {
        behavior: 'smooth'
      },
      500
    );
  }, []);

  const ref = useRef();

  useOutsideClick(ref, () => {
    setShowDrop(false);
  });

  const currentLocation = useLocation();

  const refreshPage = () => {};

  return (
    <Fragment>
      <header className="sticky-top">
        <nav>
          <div className={`${navbar ? 'sticky-nav ' : 'nav-area'} `}>
            <div className="logo">
              {' '}
              <a href="/" aria-label="Alft Logo Link To Home Page" onClick={refreshPage}>
                <img src="/assets/img/alft-logo.webp" alt="Alft Logo" />
              </a>
            </div>
            <div className="nav-items">
              <a
                onClick={refreshPage}
                className={
                  currentLocation.pathname === '/'
                    ? !currentLocation.hash.includes('#services')
                      ? 'active-style'
                      : ''
                    : ''
                }
                href="/"
              >
                Home
              </a>
              <a
                className={({ isActive }) => (isActive ? 'active-style' : '')}
                href="/about"
                onClick={refreshPage}
              >
                About us
              </a>

              <div ref={ref} className="pd-btn" onClick={() => setShowDrop(!showDrop)}>
                Products{' '}
                <svg
                  className={`${showDrop && 'change-dir'}`}
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  aria-hidden="true"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
              </div>
              <div className={`${showDrop ? 'pd-active' : 'pd-unactive'}`}>
                <ul>
                  <li>
                    <a href="/beverages" onClick={refreshPage}>
                      Beverages
                    </a>
                  </li>
                  <li>
                    <a href="/food" onClick={refreshPage}>
                      Food
                    </a>
                  </li>
                  <li>
                    <a href="/frozen-food" onClick={refreshPage}>
                      Frozen Food
                    </a>
                  </li>
                  <li>
                    <a href="/personal-care" onClick={refreshPage}>
                      Personal Care
                    </a>
                  </li>
                  <li>
                    <a href="/health-care" onClick={refreshPage}>
                      Health Care
                    </a>
                  </li>
                  <li>
                    <a href="/pet-care" onClick={refreshPage}>
                      Pet care
                    </a>
                  </li>
                  <li>
                    <a href="/home-care" onClick={refreshPage}>
                      Home Care
                    </a>
                  </li>
                </ul>
              </div>

              <a
                href={'/#services'}
                // smooth
                className={currentLocation.hash.includes('#services') ? 'active-style' : ''}
              >
                Services
              </a>
              <a
                className={({ isActive }) => (isActive ? 'active-style' : '')}
                href="/news-and-events"
                onClick={refreshPage}
              >
                News & events
              </a>
              <a
                onClick={refreshPage}
                className={({ isActive }) => (isActive ? 'active-style' : '')}
                href="/careers"
              >
                Careers
              </a>
              <a href="/contact" onClick={refreshPage}>
                <button className="contact-btn">Contact us</button>
              </a>
            </div>

            <svg
              onClick={() => setMobileNav(!mobileNav)}
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              className="d-block d-lg-none"
              height="30"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 6h16v2H4zm4 5h12v2H8zm5 5h7v2h-7z"></path>
            </svg>
          </div>
        </nav>

        {/* mobile nav  */}
        <div className={`${mobileNav ? 'show-mblnav' : 'hide-mblnav'} d-block d-lg-none`}>
          <button
            className="close"
            onClick={() => setMobileNav(false)}
            aria-label="Close Menu Button"
          >
            {' '}
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              height="30"
              width="30"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path>
            </svg>{' '}
          </button>

          <div className="contents">
            <div className="logo">
              <a href="/" aria-label="Alft Logo Link To Home Page" onClick={refreshPage}>
                <img src="/assets/img/alft-logo.webp" alt="Alft Logo" />
              </a>
            </div>

            <div className="nav-items ">
              <a
                onClick={refreshPage}
                className={
                  currentLocation.pathname === '/'
                    ? !currentLocation.hash.includes('#services')
                      ? 'active-style'
                      : ''
                    : ''
                }
                href="/"
              >
                Home
              </a>
              <a
                onClick={refreshPage}
                className={({ isActive }) => (isActive ? 'active-style' : '')}
                href="/about"
              >
                About us
              </a>
              <Accordion>
                <div>
                  <Accordion.Toggle
                    as={Button}
                    onClick={() => setShowDrop(!showDrop)}
                    variant="link"
                    eventKey="1"
                  >
                    <div className="pd-btn">
                      Products{' '}
                      <svg
                        className={`${showDrop && 'change-dir'}`}
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        aria-hidden="true"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <ul>
                      <li>
                        <a href="/beverages">Beverages</a>
                      </li>
                      <li>
                        <a href="/food">Food</a>
                      </li>
                      <li>
                        <a href="/frozen-food">Frozen Food</a>
                      </li>
                      <li>
                        <a href="/personal-care">Personal Care</a>
                      </li>
                      <li>
                        <a href="/health-care">Health Care</a>
                      </li>
                      <li>
                        <a href="/pet-care">Pet care</a>
                      </li>
                      <li>
                        <a href="/home-care">Home Care</a>
                      </li>
                    </ul>
                  </Accordion.Collapse>
                </div>
              </Accordion>

              <a
                href={'/#services'}
                // smooth
                className={currentLocation.hash.includes('#services') ? 'active-style' : ''}
                onClick={refreshPage}
              >
                Services
              </a>
              <a
                className={({ isActive }) => (isActive ? 'active-style' : '')}
                onClick={refreshPage}
                href="/news-and-events"
              >
                News & events
              </a>
              <a
                onClick={refreshPage}
                className={({ isActive }) => (isActive ? 'active-style' : '')}
                href="/careers"
              >
                Careers
              </a>
              <a
                onClick={refreshPage}
                className={({ isActive }) => (isActive ? 'active-style' : '')}
                href="/contact"
              >
                Contact us
              </a>
            </div>

            <div className="socials-wrap">
              <a
                href="https://www.linkedin.com/company/alft-packaging-company/"
                target="_blank"
                rel="noreferrer"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.1 0.299805H2.89999C1.46999 0.299805 0.299988 1.4698 0.299988 2.8998V21.0998C0.299988 22.5311 1.46999 23.6998 2.89999 23.6998H12V14.5998H9.39999V11.3823H12V8.7173C12 5.9041 13.5756 3.9281 16.8958 3.9281L19.2397 3.9307V7.3172H17.6836C16.3914 7.3172 15.9 8.287 15.9 9.1866V11.3836H19.2384L18.5 14.5998H15.9V23.6998H21.1C22.53 23.6998 23.7 22.5311 23.7 21.0998V2.8998C23.7 1.4698 22.53 0.299805 21.1 0.299805Z"
                    fill="#0083B2"
                  />
                </svg>
              </a>

              <a
                href="https://www.linkedin.com/company/alft-packaging-company/"
                target="_blank"
                rel="noreferrer"
                className="mx-3"
              >
                {' '}
                <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0.0833438 2.07467C0.0833438 1.54658 0.293127 1.04011 0.666543 0.666698C1.03996 0.293282 1.54642 0.0834987 2.07451 0.0834987H21.9233C22.1851 0.0830713 22.4443 0.134267 22.6862 0.234154C22.9281 0.334042 23.1479 0.480659 23.333 0.665613C23.5182 0.850567 23.665 1.07022 23.7652 1.31201C23.8653 1.55379 23.9168 1.81296 23.9167 2.07467V21.9235C23.917 22.1853 23.8656 22.4445 23.7656 22.6864C23.6656 22.9283 23.5189 23.1481 23.3339 23.3333C23.1488 23.5184 22.9291 23.6652 22.6872 23.7654C22.4454 23.8655 22.1862 23.917 21.9244 23.9168H2.07451C1.81294 23.9168 1.55392 23.8653 1.31227 23.7652C1.07062 23.665 0.851071 23.5183 0.66616 23.3333C0.481249 23.1482 0.334603 22.9286 0.234601 22.6869C0.1346 22.4452 0.0832015 22.1862 0.0833438 21.9246V2.07467ZM9.51701 9.1705H12.7443V10.7912C13.2101 9.8595 14.4018 9.021 16.1925 9.021C19.6256 9.021 20.4392 10.8768 20.4392 14.2817V20.5888H16.9649V15.0573C16.9649 13.1182 16.4991 12.024 15.3161 12.024C13.6748 12.024 12.9923 13.2038 12.9923 15.0573V20.5888H9.51701V9.1705ZM3.55868 20.4404H7.03401V9.021H3.55868V20.4393V20.4404ZM7.53126 5.2965C7.53781 5.59406 7.48486 5.88994 7.37552 6.16676C7.26618 6.44358 7.10264 6.69577 6.89451 6.90853C6.68638 7.1213 6.43785 7.29035 6.1635 7.40576C5.88916 7.52117 5.59452 7.58062 5.29689 7.58062C4.99925 7.58062 4.70462 7.52117 4.43027 7.40576C4.15592 7.29035 3.90739 7.1213 3.69926 6.90853C3.49113 6.69577 3.32759 6.44358 3.21825 6.16676C3.10891 5.88994 3.05596 5.59406 3.06251 5.2965C3.07537 4.71242 3.31643 4.15661 3.73405 3.74808C4.15168 3.33955 4.71267 3.11078 5.29689 3.11078C5.8811 3.11078 6.44209 3.33955 6.85972 3.74808C7.27734 4.15661 7.5184 4.71242 7.53126 5.2965Z"
                    fill="#0083B2"
                  />
                </svg>
              </a>

              <a
                href="https://www.linkedin.com/company/alft-packaging-company/"
                target="_blank"
                rel="noreferrer"
              >
                {' '}
                <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.4969 8.33156C10.2016 8.33156 8.32846 10.2047 8.32846 12.5C8.32846 14.7953 10.2016 16.6684 12.4969 16.6684C14.7922 16.6684 16.6653 14.7953 16.6653 12.5C16.6653 10.2047 14.7922 8.33156 12.4969 8.33156ZM24.9991 12.5C24.9991 10.7738 25.0147 9.0633 24.9178 7.34026C24.8209 5.33891 24.3643 3.56271 22.9008 2.09922C21.4342 0.632607 19.6611 0.179175 17.6598 0.082235C15.9336 -0.0147055 14.2231 0.000930141 12.5 0.000930141C10.7739 0.000930141 9.06333 -0.0147055 7.34029 0.082235C5.33894 0.179175 3.56274 0.635734 2.09925 2.09922C0.632637 3.56584 0.179206 5.33891 0.0822655 7.34026C-0.0146749 9.06643 0.000960659 10.777 0.000960659 12.5C0.000960659 14.223 -0.0146749 15.9367 0.0822655 17.6597C0.179206 19.6611 0.635764 21.4373 2.09925 22.9008C3.56587 24.3674 5.33894 24.8208 7.34029 24.9178C9.06646 25.0147 10.777 24.9991 12.5 24.9991C14.2262 24.9991 15.9367 25.0147 17.6598 24.9178C19.6611 24.8208 21.4373 24.3643 22.9008 22.9008C24.3674 21.4342 24.8209 19.6611 24.9178 17.6597C25.0179 15.9367 24.9991 14.2262 24.9991 12.5ZM12.4969 18.9137C8.94763 18.9137 6.08319 16.0493 6.08319 12.5C6.08319 8.95073 8.94763 6.08629 12.4969 6.08629C16.0462 6.08629 18.9106 8.95073 18.9106 12.5C18.9106 16.0493 16.0462 18.9137 12.4969 18.9137ZM19.1733 7.3215C18.3446 7.3215 17.6754 6.6523 17.6754 5.82361C17.6754 4.99493 18.3446 4.32573 19.1733 4.32573C20.002 4.32573 20.6712 4.99493 20.6712 5.82361C20.6714 6.02039 20.6328 6.21528 20.5577 6.39712C20.4825 6.57897 20.3721 6.74419 20.233 6.88333C20.0939 7.02247 19.9286 7.1328 19.7468 7.20798C19.565 7.28317 19.3701 7.32175 19.1733 7.3215Z"
                    fill="#0083B2"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;
