import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { Provider } from 'react-redux';
import store from './redux';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-P67WZFGL'
};
TagManager.initialize(tagManagerArgs);

const MainApp = (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(MainApp, document.getElementById('root'));
const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(MainApp, rootElement);
} else {
  ReactDOM.render(MainApp, rootElement);
}
