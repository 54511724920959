import React from 'react';

const ProductCard = ({ product }) => {
  return (
    <div className="pd-card">
      <img className="" src={product.img} alt="" />
      <div>
        <h5 style={{ textTransform: 'capitalize' }}>
          {product.type.split('-').join(' ')}
          <svg
            width="15"
            height="13"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M0.25 6.50017C0.25 6.25982 0.34548 6.02931 0.515435 5.85935C0.685389 5.6894 0.915898 5.59392 1.15625 5.59392H11.6561L7.76462 1.70429C7.59446 1.53412 7.49885 1.30332 7.49885 1.06267C7.49885 0.822013 7.59446 0.591213 7.76462 0.421043C7.93479 0.250874 8.16559 0.155273 8.40625 0.155273C8.64691 0.155273 8.87771 0.250874 9.04788 0.421043L14.4854 5.85854C14.5698 5.94273 14.6367 6.04273 14.6824 6.15283C14.7281 6.26293 14.7516 6.38097 14.7516 6.50017C14.7516 6.61937 14.7281 6.7374 14.6824 6.8475C14.6367 6.9576 14.5698 7.05761 14.4854 7.14179L9.04788 12.5793C8.87771 12.7495 8.64691 12.8451 8.40625 12.8451C8.16559 12.8451 7.93479 12.7495 7.76462 12.5793C7.59446 12.4091 7.49885 12.1783 7.49885 11.9377C7.49885 11.697 7.59446 11.4662 7.76462 11.296L11.6561 7.40642H1.15625C0.915898 7.40642 0.685389 7.31094 0.515435 7.14099C0.34548 6.97103 0.25 6.74052 0.25 6.50017Z"
              fill="white"
            />
          </svg>
        </h5>
      </div>
    </div>
  );
};

export default ProductCard;
