import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import careerService from '../services/careerService';

const initialState = {
  jobListings: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ''
};

// Get all job listings
export const getAllJobListings = createAsyncThunk('jobListings', async (_, thunkAPI) => {
  try {
    const data = await careerService.getAllJobListings();
    const filteredData = data.filter((d) => d.status === 'approved' && d.isActive === true);
    return filteredData;
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const jobListingSlice = createSlice({
  name: 'jobListing',
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllJobListings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllJobListings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.jobListings = action.payload;
      })
      .addCase(getAllJobListings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = jobListingSlice.actions;
export default jobListingSlice.reducer;
