import React, { Fragment } from 'react';
import Header from '../wrappers/header/Header';
import Footer from '../wrappers/footer/Footer';
import { Helmet } from 'react-helmet';

const MainLayout = ({ children, title, description, image }) => {
  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta
          name="keywords"
          content="ALFT, Innovative Packaging, BRC AA, Packaging, Beverages, Food, Frozen Food, Personal Care, Pet Care, Health Care, Home Care, Sri Lanka"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="ALFT Packaging (Pvt) Ltd" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="description"
          content={
            description
              ? description
              : 'Sri Lanka’s Only BRC AA Certified Flexible Packaging Facility'
          }
        />
        <meta
          property="og:description"
          content={
            description
              ? description
              : 'Sri Lanka’s Only BRC AA Certified Flexible Packaging Facility'
          }
        />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={image ? image : '/og-image.webp'} />
        <meta property="og:image:alt" content={title} />
        <meta name="twitter:title" content={title} />
        <meta
          name="twitter:description"
          content={
            description
              ? description
              : 'Sri Lanka’s Only BRC AA Certified Flexible Packaging Facility'
          }
        />
        <meta name="twitter:image" content={image ? image : '/og-image.webp'} />
        <meta name="twitter:site" content="ALFT Packaging (Pvt) Ltd" />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </Fragment>
  );
};

export default MainLayout;
