import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';

import './assets/scss/style.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Home from './pages/home/Home';
import About from './pages/about/About';
import NotFound from './pages/not-found/Notfound';
import Beverages from './pages/products/Beverages';
import FoodIndustry from './pages/products/FoodIndustry';
import HealthCare from './pages/products/HealthCare';
import HomeCare from './pages/products/HomeCare';
import PersonalCare from './pages/products/PersonalCare';
import PetCare from './pages/products/PetCare';
import FrozenFood from './pages/products/FrozenFood';
import Register from './pages/Register/Register';
import NewsAndEvents from './pages/newsAndEvents/NewsAndEvents';
import SingleNews from './pages/newsAndEvents/SingleNews';
import Careers from './pages/Careers/Careers';
import Contact from './pages/contact/Contact';
import PrivacyPolicy from './pages/others/PrivacyPolicy';
import ThankYou from './pages/thank-you/ThankYou';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);

const App = (props) => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <>
      <Routes>
        <Route path={'/'} element={<Home />} />
        <Route path={'/about'} element={<About />} />
        <Route path={'/register'} element={<Register />} />
        <Route path={'/beverages'} element={<Beverages />} />
        <Route path={'/food'} element={<FoodIndustry />} />
        <Route path={'/personal-care'} element={<PersonalCare />} />
        <Route path={'/pet-care'} element={<PetCare />} />
        <Route path={'/health-care'} element={<HealthCare />} />
        <Route path={'/home-care'} element={<HomeCare />} />
        <Route path={'/frozen-food'} element={<FrozenFood />} />
        <Route path={'/news-and-events'} element={<NewsAndEvents />} />
        <Route path={'/careers'} element={<Careers />} />
        <Route path={'/contact'} element={<Contact />} />
        <Route path={'/thank-you'} element={<ThankYou />} />
        <Route path={'/privacy-policy'} element={<PrivacyPolicy />} />
        <Route path={'/news-and-events/:id'} element={<SingleNews />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer progressClassName="toastProgress" bodyClassName="toastBody" />
    </>
  );
};

export default App;
