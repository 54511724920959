import React from 'react';
import { Link } from 'react-router-dom';

const BlogCard = ({ blog }) => {
  return (
    <article className="blog-card ">
      <img src={blog?.coverImage} className="img-fluid" alt="" />
      <div className="content">
        <div className="d-flex justify-content-between align-items-center">
          <div className="category">{blog?.category}</div>
          <div className="date">{blog?.date}</div>
        </div>
        <h4>{blog?.title}</h4>
        <Link to={`/news-and-events/${blog._id}`}>
          <button>Read More</button>
        </Link>
      </div>
    </article>
  );
};

export default BlogCard;
