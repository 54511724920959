import React, { useEffect } from 'react';
import { useState } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import ApplyModal from './ApplyModal';
import Spinner from './Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { getAllJobListings } from '../redux/reducers/careerSlice';

// import Accordion from 'react-bootstrap/Accordion';

export const ToggleButtonAcc = ({ idx }) => {
  const [vOpen, setVOpen] = useState(false);

  return (
    <>
      <Accordion.Toggle
        as={Button}
        onClick={() => setVOpen(!vOpen)}
        variant="link"
        eventKey={idx + 1}
      >
        View Details
        <svg
          className={`${vOpen && 'change-dir'} ml-2`}
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="img"
          width="1em"
          height="1em"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path fill="#0083B2" d="M2.43 4.8L0 7.222L12 19.2L24 7.222L21.57 4.8L12 14.347z" />
        </svg>
      </Accordion.Toggle>
    </>
  );
};

export const Vacancies = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [jobId, setJobId] = React.useState('');
  const dispatch = useDispatch();
  const { jobListings, isLoading } = useSelector((state) => state.careers);
  useEffect(() => {
    dispatch(getAllJobListings());
  }, [dispatch]);

  return (
    <>
      {isLoading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : (
        <Accordion>
          {jobListings?.map((i, idx) => {
            return (
              <div className="vacancy">
                <div className="header">
                  <div style={{ width: '50%', display: 'flex' }}>
                    <div className="type">{i.department}</div>
                    <h4>{i.jobRole}</h4>
                  </div>
                  <div>
                    <span>{i.jobType}</span>
                    <ToggleButtonAcc idx={idx} key={idx}></ToggleButtonAcc>
                  </div>
                </div>

                <Accordion.Collapse eventKey={idx + 1}>
                  <div className="content pb-5">
                    <div dangerouslySetInnerHTML={{ __html: i.jobRequirements }}></div>
                    <button
                      onClick={() => {
                        setJobId(i._id);
                        setModalShow(true);
                      }}
                    >
                      Apply Now
                    </button>
                  </div>
                </Accordion.Collapse>
              </div>
            );
          })}
        </Accordion>
      )}
      <ApplyModal show={modalShow} onHide={() => setModalShow(false)} jobId={jobId} />
    </>
  );
};
