import axios from 'axios';

const responseUrl = process.env.REACT_APP_SERVER_RESPONSES_URL;

// Get single newsletter
const postResponse = async (messageData) => {
  const response = await axios.post(responseUrl, messageData);

  return response.data;
};

const contactService = {
  postResponse
};

export default contactService;
