import React from 'react';
import { Container } from 'react-bootstrap';

import AlftBanner from '../../components/AlftBanner';
import MainLayout from '../../layouts/MainLayout';

const ThankYou = () => {
  const handleGoHome = () => {
    window.location.href = '/';
  };

  return (
    <MainLayout title="Thank you | ALFT Packaging (Pvt) Ltd">
      <section className="bg-myLight">
        <Container>
          <section className="contact thank-you">
            <div className="texts">
              <h3 className="d-none d-md-block d-lg-block">Get in touch with us</h3>
              <h4>
                <a
                  href="tel:+94706505192"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="Alft Phone Number"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    className="mr-3"
                    height="23"
                    width="23"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                  </svg>
                  +94706505192
                </a>
              </h4>
              <h4>
                <a href="malito:info@alft.lk" aria-label="Alft Email">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    className="mr-3"
                    height="23"
                    width="23"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
                  </svg>
                  info@alft.lk
                </a>
              </h4>
              <h4 className="d-flex align-items-center">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 384 512"
                  className="mr-3"
                  height="23"
                  width="23"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                </svg>
                No. 52 Cinnamon Garden, Ekala,
                <br /> Kotugoda, Sri Lanka
              </h4>
              <h4>
                <a
                  href=" https://www.linkedin.com/company/alft-packaging-company/ "
                  target="_blank"
                  aria-label="Alft LinkedIn"
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    className="mr-3"
                    height="23"
                    width="23"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                  </svg>
                  ALFT Packaging
                </a>
              </h4>
              <div className="map-box">
                <iframe
                  title="ALFT - Location"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3959.076049280918!2d79.89824597548876!3d7.117185715978727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2f066c957fde1%3A0x276e001d23250fb0!2sALFT%20Packaging%20Co.%20(Pvt)%20Ltd!5e0!3m2!1sen!2slk!4v1694678315675!5m2!1sen!2slk&amp;disableDefaultUI=true"
                  width="100%"
                  style={{ border: '0', borderRadius: '15px' }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>

            <div className="register mt-5">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <img
                  src="/assets/img/happy_announcement.svg"
                  style={{
                    width: '100%',
                    maxWidth: '380px',
                    objectFit: 'contain'
                  }}
                />
              </div>

              <h1 className="d-block d-lg-none d-md-none main-heading text-center">
                {/* mobile */}
                Thank you for contacting us!
              </h1>

              <h1 className="d-none d-md-block d-lg-block text-center main-heading">
                {/* desktop */}
                Thank You!
              </h1>

              <p className="text-center font-weight-medium mt-1">
                We have received your message and will get back to you as soon as possible.
              </p>

              <button onClick={handleGoHome} type="submit">
                Go back to Home
              </button>
            </div>
          </section>
        </Container>
      </section>

      <div className="alft-wrap-contact">
        <AlftBanner
          data={{
            heading: 'Interested in becoming a supplier to ALFT?',
            btnText: 'Register Now!',
            route: '/register'
          }}
        ></AlftBanner>
      </div>
    </MainLayout>
  );
};

export default ThankYou;
