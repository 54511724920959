import React, { Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Solution from '../../components/solution';
import MainLayout from '../../layouts/MainLayout';
import { allData, categoryData } from './allData.js';
import ProductCard from '../../components/productCard';
import LetsTalkBanner from '../../components/LetsTalkBanner';
import { Link } from 'react-router-dom';
import OurCustomers from './OurCustomers';
import AlftBanner from '../../components/AlftBanner';
import WhyUs from '../../components/whyUs';
import { useState } from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import VideoPlayer from '../../components/VideoPlayer';
import { NavHashLink } from 'react-router-hash-link';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';

import VideoComponent from '../../components/videoComponent';

const Home = () => {
  const [focus, setFocus] = React.useState(false);

  // packaging items  toggling
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showMainMenu, setShowMainMenu] = useState(true);
  const [showMenuCards, setShowMenuCards] = useState(false);
  const [solidLiquidData, setSolidLiquid] = useState(null);
  const [cardsData, setCardsData] = useState(null);

  const handleBack = () => {
    if (showSubMenu === true) {
      setShowSubMenu(false);
      setShowMainMenu(true);
    }
    if (!showSubMenu && showMenuCards) {
      setShowSubMenu(true);
      setShowMenuCards(false);
    }
  };

  const handleSolidLiquid = (title) => {
    setShowSubMenu(false);
    setShowMenuCards(true);
    setShowMainMenu(false);
    const matchedData = solidLiquidData.filter((c) => {
      return c.title === title;
    });
    setCardsData(matchedData[0].menuCardData);
  };
  const CustomPrevArrow = (props) => {
    const { onClick, currentSlide } = props;
    const isFirstSlide = currentSlide === 0;
    return (
      <button
        className="slider-nav-btn"
        style={{
          opacity: isFirstSlide ? '0.6' : '1',
          pointerEvents: isFirstSlide ? 'none' : 'all'
        }}
        onClick={onClick}
        aria-label="previous-arrow"
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 1024 1024"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M689 165.1L308.2 493.5c-10.9 9.4-10.9 27.5 0 37L689 858.9c14.2 12.2 35 1.2 35-18.5V183.6c0-19.7-20.8-30.7-35-18.5z"></path>
        </svg>
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick, currentSlide, slideCount } = props;
    const isLastSlide = currentSlide === slideCount - 1.5 || currentSlide === slideCount - 1;
    return (
      <button
        className="slider-nav-btn"
        style={{
          left: '52%',
          opacity: isLastSlide ? '0.6' : '1',
          pointerEvents: isLastSlide ? 'none' : 'all'
        }}
        onClick={onClick}
        aria-label="next-arrow"
      >
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 1024 1024"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M715.8 493.5L335 165.1c-14.2-12.2-35-1.2-35 18.5v656.8c0 19.7 20.8 30.7 35 18.5l380.8-328.4c10.9-9.4 10.9-27.6 0-37z"></path>
        </svg>
      </button>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 1.5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />
  };

  return (
    <Fragment>
      <MainLayout title="ALFT Packaging (Pvt) Ltd">
        <Container>
          <section className="hero-sec">
            <div className="text-area">
              <h1>
                Sri Lanka’s Only BRC AA <br className="d-none d-lg-block" /> Certified Flexible{' '}
                <br className="d-none d-lg-block" /> Packaging Facility
              </h1>
              <p>
                ALFT is a future-oriented organization, dedicated to preserving communities and the
                environment, while promoting a culture of diversity and championing the cause of
                sustainable development.
              </p>
              <NavHashLink smooth to="#services" className="arrow-btn">
                <span>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 448 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                  </svg>
                </span>
                Our Expertise
              </NavHashLink>
            </div>
            <img
              loading="eager"
              className="img-fluid"
              src="./assets/img/hero-section-img.webp"
              alt="ALFT Hero"
            />
          </section>
        </Container>

        <section className="partner">
          <div className="item-box">
            <CountUp start={focus ? 0 : null} end={120} duration={5} redraw={true}>
              {({ countUpRef }) => (
                <div className="item-wraper">
                  <div className="d-flex justify-content-center align-items-center">
                    {' '}
                    <h3 ref={countUpRef} />
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setFocus(true);
                        }
                      }}
                    >
                      <h3>+</h3>
                    </VisibilitySensor>
                  </div>
                  <h4>PARTNERS</h4>
                </div>
              )}
            </CountUp>
          </div>

          <div className="item-box">
            <CountUp start={focus ? 0 : null} end={6} duration={5} redraw={true}>
              {({ countUpRef }) => (
                <div className="item-wraper">
                  <div className="d-flex justify-content-center align-items-center ">
                    {' '}
                    <h3 ref={countUpRef} />
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setFocus(true);
                        }
                      }}
                    >
                      <h3>+</h3>
                    </VisibilitySensor>
                  </div>
                  <h4>INDUSTRIES</h4>
                </div>
              )}
            </CountUp>
          </div>
          <hr className="d-block d-md-none d-lg-none" />
          <div className="item-box">
            <CountUp start={focus ? 0 : null} end={1000} duration={4} redraw={true}>
              {({ countUpRef }) => (
                <div className="item-wraper">
                  <div className="d-flex justify-content-center align-items-center">
                    {' '}
                    <h3 ref={countUpRef} />
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setFocus(true);
                        }
                      }}
                    >
                      <h3>+</h3>
                    </VisibilitySensor>
                  </div>
                  <h4>CUSTOMERS</h4>
                </div>
              )}
            </CountUp>
          </div>
        </section>

        <section className="video">
          <div>
            <VideoPlayer title="Promotional Video" videoId="0WbdXOt8WbY" />
          </div>
        </section>

        <section className="certificate">
          <div className="img-part">
            <img src="./assets/img/view-certificate-sec.webp" alt="" />
          </div>
          <div className="pl-0 pl-lg-3">
            <div className="learn-more">
              <p>
                ALFT Packaging Company (Pvt) Ltd. was established in the year 2017. It supplied a{' '}
                <br className="d-none d-lg-block" />
                much-demanded service of international standard, high-quality, next-generation
                packaging solutions.{' '}
              </p>
              <NavLink to="/about">Learn More</NavLink>
            </div>

            <div className="navy-box">
              <div className="wi-lg-75">
                <div className="d-flex  align-items-center">
                  <img src="./assets/img/brc.webp" alt="" />
                  <h5>Sri Lanka’s Only BRC AA Certified Packaging Facility</h5>
                </div>
                <div>
                  <p>
                    The BRC certification offered by the British Retail Consortium denotes food
                    quality, safety and responsibility. ALFT is the only flexible packaging plant in
                    Sri Lanka that has been awarded the BRC AA certification.{' '}
                  </p>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://directory.brcgs.com/site/1619399"
                  >
                    {' '}
                    View Certificate
                    <svg
                      width="10"
                      height="11"
                      viewBox="0 0 10 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.55646 1.97606C3.14225 1.97606 2.80646 1.62786 2.80646 1.19834C2.80646 0.76882 3.14225 0.420624 3.55646 0.420624L9.21331 0.420624C9.62753 0.420624 9.96332 0.768821 9.96331 1.19834L9.96332 7.06425C9.96332 7.49378 9.62753 7.84197 9.21332 7.84197C8.7991 7.84197 8.46332 7.49377 8.46332 7.06425L8.46332 3.07592L1.61192 10.1805C1.31902 10.4842 0.844151 10.4842 0.551257 10.1805C0.258364 9.8768 0.258365 9.38438 0.551257 9.08066L7.40265 1.97606L3.55646 1.97606Z"
                        fill="#95F400"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="partner-video-section" style={{ width: '100%' }}>
          <h1 className="main-h1">What our Partners say</h1>
          <Slider {...settings}>
            {allData.testimonialVideoData.map((e, index) => (
              <VideoComponent
                client={e.client}
                role={e.role}
                videoID={e.videoID}
                videoTitle={e.videoTitle}
                image={e.image}
                key={index}
              />
            ))}
          </Slider>
        </section>

        {/* packaging section  */}
        <section className={`packaging container ${showSubMenu || showMenuCards ? 'h-100' : ''}`}>
          <h1 className="main-h1 mt-5">Flexible Packaging</h1>

          {!showMainMenu || showSubMenu || showMenuCards ? (
            <div className="back-reset">
              <div className="back-reset">
                <button onClick={handleBack}>
                  Back
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2"
                    aria-hidden="true"
                    role="img"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="black"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m11 5l-7 7l7 7m-7-7h16"
                    />
                  </svg>
                </button>

                <button
                  onClick={() => {
                    setShowSubMenu(false);
                    setShowMenuCards(false);
                    setShowMainMenu(true);
                  }}
                >
                  Reset
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="ml-2"
                    aria-hidden="true"
                    role="img"
                    width="1em"
                    height="1em"
                    preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="black"
                      d="M480 256c0 123.4-100.5 223.9-223.9 223.9c-48.86 0-95.19-15.58-134.2-44.86c-14.14-10.59-17-30.66-6.391-44.81c10.61-14.09 30.69-16.97 44.8-6.375c27.84 20.91 61 31.94 95.89 31.94C344.3 415.8 416 344.1 416 256S344.33 96.2 256.2 96.2c-50.3.02-97.6 24.1-127.6 63.8H192c17.67 0 32 14.31 32 32s-14.3 32-32 32H48c-17.67 0-32-14.31-32-32V48c0-17.69 14.33-32 32-32s32 14.31 32 32v70.23c42.1-53.65 106.1-86.12 176.1-86.12C379.5 32.11 480 132.6 480 256z"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ) : (
            ''
          )}

          {showMainMenu ? (
            <div className="categories">
              {/* liquid  */}
              <div
                className="category"
                onClick={() => {
                  setShowSubMenu(true);
                  setShowMainMenu(false);
                  setSolidLiquid(categoryData?.liquid);
                }}
              >
                {/* solid  */}
                <div>
                  <img src="./assets/img/liquid.webp" alt="" />
                  <h5>Liquid</h5>
                </div>
              </div>
              <div
                className="category"
                onClick={() => {
                  setShowSubMenu(true);
                  setShowMainMenu(false);
                  setSolidLiquid(categoryData?.solid);
                }}
              >
                <div>
                  {' '}
                  <img src="./assets/img/solid.webp" alt="" />
                  <h5>Solid</h5>
                </div>
              </div>
            </div>
          ) : (
            showSubMenu && (
              <div className="categories">
                {solidLiquidData?.map((item) => (
                  <div
                    key={item.id}
                    className="category"
                    onClick={() => handleSolidLiquid(item.title)}
                  >
                    <div>
                      <img src={item.img} alt="" />
                      <h5>{item.title}</h5>
                    </div>
                  </div>
                ))}
              </div>
            )
          )}
          {showMenuCards && (
            <div className="pack-cards">
              {cardsData?.map((item) => (
                <div key={item.id} className="pack-card">
                  <img src={item.img} alt="" />
                  <div>
                    <h5>{item.title}</h5>
                    <p>{item.info}</p>
                    <NavLink to="/contact">Place an Order</NavLink>
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>

        {/* services  */}
        <section className="innovative" id="services">
          <h1 className="main-h1">Our Innovative Solutions</h1>

          <div className="solutions-wrapper">
            {allData.solutionData.map((solution) => (
              <Solution key={solution.id} solution={solution}></Solution>
            ))}
          </div>
        </section>

        {/* lets talk banner  */}
        <LetsTalkBanner
          heading={
            'The only purpose built plant in Sri Lanka for food and Pharmaceuticals packaging'
          }
          route={'/contact'}
        ></LetsTalkBanner>

        {/* why us */}
        <WhyUs></WhyUs>

        {/* products  */}
        <Container>
          <section className="products">
            <h1 className="main-h1">Our Product Range</h1>
            <div className="pd-wrapper">
              <Row xs={1} md={2} lg={3} className="gx-4 gy-5">
                {allData.pdData.slice(0, 6).map((product) => (
                  <Link to={`/${product.type.split(' ').join('')}`} key={product.id}>
                    <Col>
                      <ProductCard product={product}></ProductCard>
                    </Col>
                  </Link>
                ))}
              </Row>
              <Row>
                {allData.pdData.slice(6, 7).map((product) => (
                  <Link to={`/${product.type}`} key={product.id}>
                    <Col xs={12} sm={12}>
                      <ProductCard product={product}></ProductCard>
                    </Col>
                  </Link>
                ))}
                <Col lg={true}>
                  <div className="large-card ">
                    <div className="border"></div>

                    <div className="d-flex h-100 align-items-center ">
                      <div className="content">
                        <h4>ALFT guarantees unmatched service, quality, and flexibility </h4>
                        <Link to="/contact">
                          <button className="arrow-btn">
                            <span>
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 448 512"
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                              </svg>
                            </span>
                            Get our Service
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
        </Container>

        {/* A Glance at our Customers */}
        <OurCustomers></OurCustomers>

        {/* banner */}
        <div className="alft-banner-wrapper">
          <AlftBanner
            data={{
              heading: 'Interested in becoming a supplier to ALFT?',
              btnText: 'Register Now!',
              route: '/register'
            }}
          ></AlftBanner>
        </div>
      </MainLayout>
    </Fragment>
  );
};

export default Home;
