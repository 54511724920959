import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import supplierService from '../services/supplierService';

const initialState = {
  suppliers: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ''
};

export const newSupplier = createAsyncThunk('new-supplier', async (applicantData, thunkAPI) => {
  try {
    return await supplierService.newSupplier(applicantData);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const supplierSlice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(newSupplier.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(newSupplier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(newSupplier.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = supplierSlice.actions;
export default supplierSlice.reducer;
