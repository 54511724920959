import axios from 'axios';

const newsAndEventUrl = process.env.REACT_APP_SERVER_NEWSANDEVENTS_URL;

// Get all job listing
const getAllPosts = async () => {
  const response = await axios.get(newsAndEventUrl);
  return response.data;
};

// Get single news and event
const getPost = async (newsAndEventId) => {
  const response = await axios.get(`${newsAndEventUrl}/${newsAndEventId}`);

  return response.data;
};

const newsAndEventService = {
  getAllPosts,
  getPost
};

export default newsAndEventService;
