import React from 'react';
import { NavHashLink } from 'react-router-hash-link';
import MainLayout from '../../layouts/MainLayout';

const PrivacyPolicy = () => {
  return (
    <MainLayout title="Privacy Policy | ALFT Packaging (Pvt) Ltd">
      <section className="privacy-policy">
        <h1 className="main-heading">Privacy Policy for ALFT Packaging</h1>
        <h6>Last updated on 22nd July 2022</h6>

        <div className="first-para">
          <p>
            At ALFT Packaging, which is accessible from{' '}
            <a href="https://www.alft.lk/">https://www.alft.lk/</a>, we always ensure that the
            information collected from you is protected at all costs. This privacy policy document
            that we present to you is relevant to this website and we mention the various
            information that is collected and all the relevant steps that are taken to safeguard
            them.
          </p>
          <p className="my-3">
            If you have additional questions or require more information about our Privacy Policy,
            do not hesitate to contact us.
          </p>
          <p>
            By providing us information through our forums, you signify your acceptance of our
            Privacy Policy. ALFT Packaging is the sole owner of this website including all the
            information that you submit through it.
          </p>
        </div>

        <div className="points">
          <h5>What this privacy policy discloses</h5>
          <ul>
            <li>
              <NavHashLink to={'/privacy-policy#info'} smooth>
                Information that we collect and how we manage them
              </NavHashLink>
            </li>
            <li>
              <NavHashLink to={'/privacy-policy#how-we-collect'} smooth>
                How do we collect your information?
              </NavHashLink>
            </li>
            <li>
              <NavHashLink to={'/privacy-policy#what-we-do'} smooth>
                What do we do with your information?
              </NavHashLink>
            </li>
            <li>
              <NavHashLink to={'/privacy-policy#third-party'} smooth>
                Third-party privacy policies
              </NavHashLink>
            </li>
            <li>
              <NavHashLink to={'/privacy-policy#gdpr'} smooth>
                GDPR Data Protection Rights
              </NavHashLink>
            </li>
            <li>
              <NavHashLink to={'/privacy-policy#child-info'} smooth>
                Children’s information
              </NavHashLink>
            </li>
            <li>
              <NavHashLink to={'/privacy-policy#contact'} smooth>
                Contact us{' '}
              </NavHashLink>
            </li>
          </ul>
        </div>

        <div className="point-details " id="info">
          <h5>Information that we collect and how we manage them</h5>
          <p>
            All information that is collected depends on your actions within the website. Your
            personal details such as your name, email, and contact number are retrieved for any
            future purposes when we need to contact you. In addition to this, any other analytical
            information required to improve ALFT Packaging will be collected.
          </p>
          <p className="my-3">
            We do not retain information more than it is necessary. We only store all information
            only until it is needed for the specific purposes it was collected for. This retention
            period may vary from different situations and requirements. Once the information passes
            the specific retention period, it will be securely disposed of.
          </p>
          <p>
            We respect each and every one of your rights and privacy. You have the right to update
            your information and ensure that your personal data is accurate and up to date to avoid
            any misunderstandings. Furthermore, if any individual requests for their personal
            information to be removed we hereby follow the request as any website is bound by law to
            delete any user information if requested according to the GDPR (General Data Protection
            Regulation). By contacting ALFT Packaging through <strong>info@alft.lk</strong> you have
            every right to request your information to be updated or removed.
          </p>
        </div>

        <div className="point-details" id="how-we-collect">
          <h5>How do we collect your information?</h5>
          <p>
            We collect personal information such as your Name, Contact number and Email Address
            which is collected through the Contact Form that is provided on the website.{' '}
          </p>
          <p>
            As analytics is an important part of a website, ALFT Packaging uses external third-party
            plugins from Google such as Google Analytics to collect, measure and analyze user
            information.
          </p>
        </div>

        <div className="point-details" id="what-we-do">
          <h5>What do we do with your information?</h5>
          <ul>
            <li>Log files</li>
          </ul>
          <p>
            The information collected for Analytics through the website is known as ‘Log Files’.
            These files log user information when you visit ALFT Packaging and track all actions
            done within the website. All hosting companies do this as a part of their hosting
            services’ analytics.
          </p>
          <p>
            These log files include information such as demographic information of the user, the
            browser type, number of clicks, Internet Service Provider (ISP), and date with a
            timestamp of when the website was accessed. We follow a standard procedure when all this
            information is collected and it will only be used for the required purposes. The main
            purpose this information is collected is to analyze what the user does to improve the
            user experience of the website for future visitors.
          </p>

          <ul>
            <li>Analytics</li>
          </ul>
          <p>
            To measure ALFT Packaging’s Analytics we use Google Analytics. The log files that are
            collected are used by these tools to give us an idea about how well the website is doing
            and what areas could improve for future visitors. Such information is valuable for ALFT
            Packaging to make sure all our visitors have a seamless experience within the website.
            The information collected is always protected in a standardized manner as both the
            third-party integrations and ALFT Packaging follow all policies to protect them.
          </p>
          <ul>
            <li>Cookies</li>
          </ul>
          <p>
            Cookies, also known as browser cookies, are small encrypted text files located in the
            browser directories. These files help web developers to navigate within a website
            efficiently to help perform certain functions. ALFT Packaging does not use any cookies
            to get information from our users but as we use third-party integrations there can be
            instances where you would have to accept any cookies. Cookies are created when a user’s
            browser loads a particular website and the user information is stored in these to
            identify regular visitors to provide a customized experience. In addition to this,
            cookies regulate how ads, widgets, and other elements function on a website.
          </p>
        </div>

        <div className="point-details" id="third-party">
          <h5>Third-party privacy policies</h5>
          <p>
            ALFT Packaging does not have any access or control over the cookies that are used by the
            third-party integrations. As we do not have any control, we kindly request you to refer
            to the respective policies of these third-party sources as it may include you about how
            their procedures take place. In addition to this, if needed, they too will provide
            instructions on how to opt-out from certain options.
          </p>
        </div>
        <div className="point-details" id="gdpr">
          <h5>GDPR - General Data Protection Regulation</h5>
          <p>
            These data protection regulations allow any individual to have easier access to the data
            that is collected and helps to manage their own information. According to these
            regulations, any user has the right to request to access, update and remove their
            personal information when needed. The full GDPR rights for individuals includes 7
            regulations about the information that is collected,
          </p>
        </div>
        <ul className="my-3 gdpr-list">
          <li>Must be processed lawfully, fairly, and in a transparent manner.</li>
          <li>Must be collected for only specified, explicit, and legitimate purposes.</li>
          <li>Must always be the adequate amount that is needed.</li>
          <li>Must be accurate and up to date.</li>
          <li>Must not be kept any longer than necessary.</li>
          <li>Must be processed in a manner that ensures the security of personal data.</li>
        </ul>
        <p>
          If you have a request to make to ALFT Packaging regarding any of these regulations, please
          contact us at <strong> info@alft.lk</strong> . We will respond to you within one month of
          clarification of your matter. More information about the GDPR Rights can be accessible
          through <strong>https://gdpr-info.eu/</strong>
        </p>

        <div className="point-details" id="child-info">
          <h5>Children’s information</h5>
          <p>
            One of the main concerns at ALFT Packaging is protection for children while using the
            internet. While we encourage parents to acknowledge and guide childrens’ activities on
            the internet, ALFT Packaging do not knowingly collect any personally identifiable
            information from anyone under the age of 13. If we become aware that we have collected
            Personal Data from anyone under the age of 13 without verification of parental consent,
            we take steps to remove that information from our servers.
          </p>
          <p>
            As we need to rely on consent as a legal basis for processing your information, a child
            would require consent from a parent. Therefore we may require parent's consent before we
            collect and use children’s information.{' '}
          </p>
        </div>

        <div className="point-details" id="contact">
          <h5>Contact us</h5>
          <p>
            If you have any questions about ALFT Packaging’s privacy policy, please do not hesitate
            to contact us through <strong>info@alft.lk</strong> We are open to any suggestions that
            you have!
          </p>
        </div>
      </section>
    </MainLayout>
  );
};

export default PrivacyPolicy;
