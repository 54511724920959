import React from 'react';

const Solution = ({ solution }) => {
  return (
    <div className="solution" id={solution.heading.toLowerCase().split(' ').join('-')}>
      <div className="img-wrap">
        {' '}
        <img src={solution.img} alt={solution.heading} />
      </div>
      <div className="texts-wrap">
        <h3>{solution.heading}</h3>
        <p>{solution.paragraph}</p>
      </div>
    </div>
  );
};

export default Solution;
