import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastConfig = {
  position: 'top-right',
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

export function successToast(message) {
  toast.success(message, toastConfig);
}

export function errorToast(message) {
  toast.error(message, toastConfig);
}
