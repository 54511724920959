import React from 'react';

const FooterBottom = () => {
  const dateTime = new Date();
  let year = dateTime.getFullYear();
  return (
    <div className="py-1 px-2 text-white text-center footer-bottom ">
      <div className="container">
        <span className="mr-lg-5 mr-md-3">
          © {year} <span className="font-weight-bold">ALFT Packaging Company (Pvt) Ltd</span>{' '}
        </span>{' '}
        <span className="d-none d-lg-inline-block d-md-inline-block">|</span>
        <span className="ml-lg-5 ml-md-3">
          Designed & Developed by{' '}
          <span className="font-weight-bold ">
            <a
              className="code94-labs"
              href="https://code94labs.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              Code94&nbsp;Labs
            </a>{' '}
          </span>
        </span>
      </div>
    </div>
  );
};

export default FooterBottom;
