import { configureStore } from '@reduxjs/toolkit';
import careersReducer from './reducers/careerSlice';
import applicantsReducer from './reducers/applicantSlice';
import newsAndEventsReducer from './reducers/newsAndEventSlice';
import contactReducer from './reducers/contactSlice';
import subscriptionReducer from './reducers/subscriptionSlice';
import suppliersReducer from './reducers/supplierSlice';

export default configureStore({
  reducer: {
    careers: careersReducer,
    applicants: applicantsReducer,
    newsAndEvents: newsAndEventsReducer,
    contact: contactReducer,
    subscription: subscriptionReducer,
    suppliers: suppliersReducer
  }
});
