import axios from 'axios';

const jobListingUrl = process.env.REACT_APP_SERVER_JOBLISTING_URL;

// Get all job listing
const getAllJobListings = async () => {
  const response = await axios.get(jobListingUrl);
  return response.data;
};

const jobListingService = {
  getAllJobListings
};

export default jobListingService;
