import React, { Fragment } from 'react';

const SingleProduct = ({ data }) => {
  return (
    <Fragment>
      <section className="single-product">
        <h1 className="main-heading">{data?.heading}</h1>
        <div className="content-wrap">
          <div>
            <p className="mb-4">{data?.para1}</p>
            <div className="categories">
              {data?.categories?.map((i, idx) => (
                <div key={idx}>
                  <strong>{i}</strong>
                </div>
              ))}
            </div>
            <p className="mt-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${data?.para2}`
                }}
              ></div>
            </p>
          </div>
          <div className="img-wrap">
            <img className="img-fluid" src={data?.img} alt="" />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SingleProduct;
