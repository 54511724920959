import React from 'react';
import VideoPlayer from './VideoPlayer';

const VideoComponent = ({ client, role, videoID, videoTitle, image }) => {
  return (
    <div className="partner-video-content">
      <div className="partner-video-player-container">
        <VideoPlayer videoId={videoID} title={videoTitle} />
      </div>
      <div className="partner-video-details">
        <img src={image} alt="Uniliver Logo" />
        <div className="text-container">
          <strong>{client}</strong>
          <span>{role}</span>
        </div>
      </div>
    </div>
  );
};

export default VideoComponent;
