import React from 'react';
import { NavLink } from 'react-router-dom';
import MainLayout from '../../layouts/MainLayout';

const NotFound = () => {
  return (
    <MainLayout title="Page Not Found | ALFT Packaging (Pvt) Ltd">
      <section className="not-found-container">
        <img src="./assets/img/lost.webp" alt="404 Lost Illustration" />
        <h1>404</h1>
        <h2>Page Not Found</h2>
        <NavLink to="/">
          <button>Back To Home</button>
        </NavLink>
      </section>
    </MainLayout>
  );
};

export default NotFound;
