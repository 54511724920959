import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import subscriptionService from '../services/subscriptionService';

const initialState = {
  response: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: ''
};

export const newSubscription = createAsyncThunk('new-subscription', async (data, thunkAPI) => {
  try {
    return await subscriptionService.newSubscription(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    reset: (state) => initialState
  },
  extraReducers: (builder) => {
    builder
      .addCase(newSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(newSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.response = action.payload;
      })
      .addCase(newSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  }
});

export const { reset } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
