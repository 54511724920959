import axios from 'axios';

const subscriptionUrl = process.env.REACT_APP_SERVER_SUBSCRIPTIONS_URL;

const newSubscription = async (data) => {
  const response = await axios.post(subscriptionUrl, data);

  return response.data;
};

const subscriptionService = {
  newSubscription
};

export default subscriptionService;
