import React from 'react';
import LetsTalkBanner from '../../components/LetsTalkBanner';
import SingleProduct from '../../components/SingleProduct';
import MainLayout from '../../layouts/MainLayout';
import { prodcutsPageData } from '../home/allData';

const FoodIndustry = () => {
  return (
    <MainLayout title="Food Industry | ALFT Packaging (Pvt) Ltd">
      <SingleProduct data={prodcutsPageData.foodIndustry}></SingleProduct>
      <div className="pd-page-banner">
        <LetsTalkBanner
          heading={
            'Equipped with highly sophisticated European Machinery for the best packaging solutions'
          }
          route={'/contact'}
        ></LetsTalkBanner>
      </div>
    </MainLayout>
  );
};

export default FoodIndustry;
