import React from 'react';
import Swiper from 'react-id-swiper';
import { photoSliderData } from '../pages/home/allData';

const photoSlider = () => {
  const settingsImg = {
    autoplay: {
      delay: 0.1,
      disableOnInteraction: false
    },
    freeMode: true,
    speed: 3000,
    watchSlidesVisibility: true,
    loop: true,
    grabCursor: true,
    breakpoints: {
      1024: {
        slidesPerView: 'auto'
      },
      768: {
        slidesPerView: 'auto'
      },
      640: {
        slidesPerView: 'auto'
      },
      320: {
        slidesPerView: 'auto'
      }
    }
  };

  return (
    <div className="customer-slider">
      <Swiper {...settingsImg}>
        {photoSliderData &&
          photoSliderData.map((single, key) => {
            return (
              <div key={key} className="item-logo">
                <img src={single.img} className={single.size} alt="" />
              </div>
            );
          })}
      </Swiper>
    </div>
  );
};

export default photoSlider;
