import axios from 'axios';

const suppliersUrl = process.env.REACT_APP_SERVER_SUPPLIERS_URL;

const newSupplier = async (data) => {
  const response = await axios.post(suppliersUrl, data);
  return response.data;
};

const supplierService = {
  newSupplier
};

export default supplierService;
