import axios from 'axios';

const applicantsUrl = process.env.REACT_APP_SERVER_APPLICANTS_URL;

const postApplication = async (applicantData) => {
  const response = await axios.post(applicantsUrl, applicantData);
  return response.data;
};

const applicantService = {
  postApplication
};

export default applicantService;
