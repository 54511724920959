import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import MainLayout from '../../layouts/MainLayout';
import { useForm } from 'react-hook-form';
import Spinner from '../../components/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { newSupplier } from '../../redux/reducers/supplierSlice';
import { errorToast, successToast } from '../../components/Toasts';

const Register = () => {
  const [certificates, setCertificates] = React.useState([]);
  const [businessRegistrations, setBusinessRegistrations] = React.useState([]);
  const [formLoading, setFormLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();

  const dispatch = useDispatch();
  const { isLoading, isError, message } = useSelector((state) => state.suppliers);

  const onSubmit = (data) => {
    setFormLoading(true);
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('email', data.email);
    formData.append('phoneNumber', data.phone);
    formData.append('address', data.address);
    formData.append('vatId', data.vat);
    formData.append('type', data.type);
    for (let i = 0; i < certificates.length; i++) {
      formData.append('certificates', certificates[i]);
    }
    for (let i = 0; i < businessRegistrations.length; i++) {
      formData.append('businessRegistration', businessRegistrations[i]);
    }
    dispatch(newSupplier(formData));
    setTimeout(() => {
      if (isLoading) {
        setFormLoading(true);
      } else {
        setFormLoading(false);
        successToast('Your Registration Details Have Been Sent');
        reset();
      }
    }, 1000);
  };

  useEffect(() => {
    if (isError) {
      errorToast(message);
    }
  }, [isError, message]);

  const handleCertificatesChange = (changeEvent) => {
    setCertificates((prevState) => [...prevState, changeEvent.target.files[0]]);
  };
  const handleBusinessRegistrationsChange = (changeEvent) => {
    setBusinessRegistrations((prevState) => [...prevState, changeEvent.target.files[0]]);
  };

  return (
    <MainLayout title="Supplier Registration | ALFT Packaging (Pvt) Ltd">
      <Container>
        <div className="register">
          <h1 className="main-heading">Register as a Supplier</h1>
          <div className="mt-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label for="name" id="name">
                  Name
                </label>
                <input
                  className={`${errors.name ? 'err-input' : 'cor-input'}`}
                  {...register('name', { required: true })}
                  type="text"
                />
              </div>
              <div>
                <label for="Email" id="Email">
                  Email
                </label>
                <input
                  className={`${errors.email ? 'err-input' : 'cor-input'}`}
                  type="email"
                  {...register('email', { required: true })}
                />
              </div>
              <div>
                <label for="address" id="address">
                  Address
                </label>
                <input
                  className={`${errors.address ? 'err-input' : 'cor-input'}`}
                  {...register('address', { required: true })}
                  type="text"
                />
              </div>
              <div>
                <label for="phone" id="phone">
                  Phone Number
                </label>
                <input
                  className={`${errors.phone ? 'err-input' : 'cor-input'}`}
                  type="number"
                  {...register('phone', {
                    required: true,
                    pattern: {
                      value: /^[0-9]*$/
                    }
                  })}
                />
              </div>
              <div>
                <label for="VAT" id="VAT">
                  VAT Registration number (Only if registered)
                </label>
                <input
                  type="text"
                  className={`${errors.vat ? 'err-input' : 'cor-input'}`}
                  {...register('vat', { required: true })}
                />
              </div>
              <div>
                <div className="">
                  <input {...register('type', { required: true })} type="radio" value="Import" />
                  <label>Import</label>
                  <div className="ml-3">
                    <input {...register('type', { required: true })} type="radio" value="Local" />
                    <label>Local</label>
                  </div>
                </div>
              </div>
              <div>
                <h5>Certifications and standardizations </h5>
                <h6>If its production raw material the following documents are a must.</h6>
                <ul>
                  <li>TDS (Technical Data Sheets) or SDS (Safety Data Sheets)</li>
                  <li>MDS (Material Data Sheets)</li>
                  <li>Other certifications (ISO, HACCP, GMP etc).</li>
                </ul>
                {certificates.length !== 0 && (
                  <>
                    {certificates.map((c) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          marginTop: '5px'
                        }}
                      >
                        <span>{c.name}</span>

                        <svg
                          className="remove-file"
                          onClick={() =>
                            setCertificates(certificates.filter((e) => e.name !== c.name))
                          }
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    ))}
                  </>
                )}
                <span className="d-flex">
                  <label for="certificates" className="fake-file">
                    Upload
                  </label>
                  <small style={{ marginTop: '2px', marginLeft: '3px' }}>(max file size 5MB)</small>
                </span>
                <input
                  type="file"
                  name="certificates"
                  id="certificates"
                  className="d-none"
                  required
                  onChange={handleCertificatesChange}
                />
              </div>
              <div>
                <h5>Business Registration </h5>
                {businessRegistrations.length !== 0 && (
                  <>
                    {businessRegistrations.map((c) => (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          marginTop: '5px'
                        }}
                      >
                        <span>{c.name}</span>
                        <svg
                          className="remove-file"
                          onClick={() =>
                            setBusinessRegistrations(
                              businessRegistrations.filter((e) => e.name !== c.name)
                            )
                          }
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 10.5858L9.17157 7.75736L7.75736 9.17157L10.5858 12L7.75736 14.8284L9.17157 16.2426L12 13.4142L14.8284 16.2426L16.2426 14.8284L13.4142 12L16.2426 9.17157L14.8284 7.75736L12 10.5858Z"></path>
                        </svg>
                      </div>
                    ))}
                  </>
                )}
                <span className="d-flex">
                  <label for="businessRegistrations" className="fake-file">
                    Upload
                  </label>
                  <small style={{ marginTop: '2px', marginLeft: '3px' }}>(max file size 5MB)</small>
                </span>
                <input
                  type="file"
                  name="businessRegistrations"
                  id="businessRegistrations"
                  required
                  className="d-none"
                  onChange={handleBusinessRegistrationsChange}
                />
              </div>

              <button type="submit">
                {formLoading ? (
                  <>
                    Registering <Spinner />
                  </>
                ) : (
                  'Register'
                )}
              </button>
            </form>
          </div>
        </div>
      </Container>
    </MainLayout>
  );
};

export default Register;
